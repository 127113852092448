import React, { useEffect, useState } from "react";
import JAN_BG from "../../assets/images/jan-bg.jpg";
import MARCH_BG from "../../assets/images/march-pic.jpg";
import APRIL_BG from "../../assets/images/april-pic.jpg";
import MAY_BG from "../../assets/images/may-pic.jpg";
import JUNE_BG from "../../assets/images/june.jpg";
import CALENDER_IMG from "../../assets/images/caendr.png";
import { useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import Slider from "react-slick";

const FestivalSeason = () => {
  const leftMonthsData = [
    { name: "January", bgImage: JAN_BG },
    { name: "February", bgImage: JAN_BG },
    { name: "March", bgImage: MARCH_BG },
    { name: "April", bgImage: APRIL_BG },
    { name: "May", bgImage: MAY_BG },
    { name: "June", bgImage: JUNE_BG },
  ];

  const rightMonthsData = [
    { name: "July", bgImage: JAN_BG },
    { name: "August", bgImage: JAN_BG },
    { name: "September", bgImage: MARCH_BG },
    { name: "October", bgImage: APRIL_BG },
    { name: "November", bgImage: MAY_BG },
    { name: "December", bgImage: JUNE_BG },
  ];

  const settings = {
    className: "tabbing-slick",
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  const HomeData = useSelector((state) => state.home.HomeData);

  const [festivalData, setFestivalData] = useState({});

  const [activeTab, setActiveTab] = useState(
    leftMonthsData[0]?.name.toLowerCase()
  );

  useEffect(() => {
    const fastivalDataList = HomeData?.festivalList;
    setFestivalData({
      january: fastivalDataList?.JanuaryList,
      february: fastivalDataList?.FebruaryList,
      march: fastivalDataList?.MarchList,
      april: fastivalDataList?.AprilList,
      may: fastivalDataList?.MayList,
      june: fastivalDataList?.JuneList,
      july: fastivalDataList?.JulyList,
      august: fastivalDataList?.AugustList,
      september: fastivalDataList?.SeptemberList,
      october: fastivalDataList?.OctoberList,
      november: fastivalDataList?.NovemberList,
      december: fastivalDataList?.DecemberList,
    });
  }, [HomeData?.festivalList]);

  const handleTabClick = (monthName) => {
    setActiveTab(monthName.toLowerCase());
  };

  return (
    <div className="festival-season">
      <div className="container">
        <div className="heading-title text-center">
          <h2>
            Festival Season of <span>India</span>
          </h2>
          <p>Checkout Festivals & Events of India</p>
        </div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <div className="left-tb tabsameclss">
            {leftMonthsData?.map((month, index) => (
              <li className="nav-item" role="presentation" key={index}>
                <button
                  className={`nav-link ${index === 0 ? "active" : ""}`}
                  id={`${month.name.toLowerCase()}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#${month.name.toLowerCase()}`}
                  type="button"
                  role="tab"
                  onClick={() => handleTabClick(month.name)}
                  aria-controls={month.name.toLowerCase()}
                  aria-selected={index === 0}
                  style={{ background: `url(${month.bgImage})` }}
                >
                  <img src={CALENDER_IMG} alt={month.name} />
                  {month.name}
                </button>
              </li>
            ))}
          </div>
          <div className="tab-content" id="myTabContent">
            {Object.keys(festivalData)?.map((month, index) => (
              <div
                key={index}
                className={`tab-pane fade ${
                  activeTab === month?.toLowerCase() ? "show active" : ""
                }`}
                id={month?.toLowerCase()}
                role="tabpanel"
                aria-labelledby={`${month?.toLowerCase()}-tab`}
              >
                {/* <OwlCarousel
                  className="owl-carousel tabbing-owl owl-theme"
                  loop
                  margin={0}
                  dots={false}
                  nav
                  autoplay={false}
                  autoplayTimeout={4000}
                  autoplayHoverPause={true}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 1,
                    },
                    1000: {
                      items: 1,
                    },
                  }}
                > */}
                {festivalData[month]?.length > 1 ? (
                  <Slider {...settings}>
                    {festivalData[month]?.map((event, eventIndex) => (
                      <div key={eventIndex} className="item">
                        <div className="tabbing-box-slid">
                          <div className="tabbing-pic">
                            <img
                              src={event?.FestivalImages}
                              alt={event?.Festivalname}
                            />
                            <div className="tabbingpcontt">
                              <span className="date-bg">
                                {event?.FestivalDate}
                              </span>
                              <h5>{event?.Festivalname}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : festivalData[month]?.length > 0 ? (
                  <div className="item">
                    <div className="tabbing-box-slid">
                      <div className="tabbing-pic">
                        <img
                          src={festivalData[month][0]?.FestivalImages}
                          alt={festivalData[month][0]?.Festivalname}
                        />
                        <div className="tabbingpcontt">
                          <span className="date-bg">
                            {festivalData[month][0]?.FestivalDate}
                          </span>
                          <h5>{festivalData[month][0]?.Festivalname}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* </OwlCarousel> */}
              </div>
            ))}
          </div>
          <div className="right-tab tabsameclss">
            {rightMonthsData?.map((month, index) => (
              <li className="nav-item" role="presentation" key={index}>
                <button
                  className="nav-link"
                  id={`${month?.name?.toLowerCase()}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#${month?.name?.toLowerCase()}`}
                  type="button"
                  role="tab"
                  onClick={() => handleTabClick(month?.name)}
                  aria-controls={month?.name?.toLowerCase()}
                  aria-selected={false}
                  style={{ background: `url(${month?.bgImage})` }}
                >
                  <img src={CALENDER_IMG} alt={month?.name} />
                  {month?.name}
                </button>
              </li>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default FestivalSeason;
