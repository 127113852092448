import React, { useEffect, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../pages/home/Home";
import Layout from "../layout/Layout";
import { publicRoutes } from "./allRoutes";
import "aos/dist/aos.css";
import Aos from "aos";
import PageNotFound from "../components/common/PageNotFound";

const MainRoute = () => {
  const location = useLocation();
  const scrollRef = useRef(null);

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    Aos.init();
    scrollToTop();
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        {publicRoutes.map((route, index) => (
          <Route path={route.path} element={route.component} key={index} />
        ))}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default MainRoute;
