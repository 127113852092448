export const NO_OF_DAYS = [
  {
    label: "1 to 3 days",
    value: "(NoOfDays BETWEEN 1 AND 3)",
  },
  {
    label: "4 to 7 days",
    value: "(NoOfDays BETWEEN 4 AND 7)",
  },
  {
    label: "8 to 13 days",
    value: "(NoOfDays BETWEEN 8 AND 13)",
  },
  {
    label: "14 to 19 days",
    value: "(NoOfDays BETWEEN 14 AND 19)",
  },
  {
    label: "More than 19 Days",
    value: "(NoOfDays > 19)",
  },
];

export const SPECIALITY_TYPE = [
  {
    label: "COUPLE TOUR",
    value: 3,
  },
  {
    label: "CUSTOMIZE TOUR",
    value: 2,
  },
  {
    label: "FAMILY TOUR",
    value: 1,
  },
];

export const TOUR_TYPE = [
  {
    label: "DELUXE",
    value: 1,
  },
  {
    label: "PREMIUM",
    value: 3,
  },
  {
    label: "ROYAL",
    value: 2,
  },
];

export const SHORT_BY_FILTER = [
  {
    label: "Price Low to High",
    value: "ORDER BY RATE ASC",
  },
  {
    label: "Price High to Low",
    value: "ORDER BY RATE DESC",
  },
  {
    label: "Duration Short to Long",
    value: "ORDER BY NoOfDays ASC",
  },
  {
    label: "Duration Long to Short",
    value: "ORDER BY NoOfDays DESC",
  },
];

export const MAP_STATE_ARRAY = [
  { label: "Jammu and Kashmir", value: 1 },
  { label: "Sikkim", value: 2 },
  { label: "Himachal Pradesh", value: 3 },
  // { label: "Goa", value: 4 },
  { label: "Kerala", value: 8 },
  { label: "Uttarakhand", value: 11 },
];
