import React from "react";
import MAP_IMAGE from "../../assets/images/maps.png";
import { useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import Slider from "react-slick";

const PackageContent = () => {
  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);

  const settings = {
    className: "tabbing-slick",
    dots: false,
    infinite: true,
    // autoplay: true,
    // autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  return (
    <>
      <div className="taour-detail-middleleft">
        <div className="big-tou-pic">
          {/* {TourDetails?.images && TourDetails?.images[0] && (
            <div class="item">
              <div class="tabbing-box-slid">
                <div class="tabbing-pic">
                  <img src={TourDetails?.images[0]?.TourImage} />
                </div>
              </div>
            </div>
          )} */}
          {/* <OwlCarousel
            className="owl-carousel tabbing-owl owl-theme"
            loop
            margin={0}
            dots={false}
            nav
            autoplay={false}
            autoplayTimeout={4000}
            autoplayHoverPause={true}
            responsive={{
              0: {
                items: 1,
              },
              600: {
                items: 1,
              },
              1199: {
                items: 1,
              },
              1400: {
                items: 1,
              },
            }}
          > */}
          <Slider {...settings}>
            {TourDetails?.images?.map((image) => (
              <div class="item">
                <div class="tabbing-box-slid">
                  <div class="tabbing-pic">
                    <img src={image?.TourImage} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          {/* </OwlCarousel> */}
        </div>
      </div>
      <div className="package-higlite-inclued">
        <div className="package-higlite">
          <h4>Package Highlights</h4>
          <div
            dangerouslySetInnerHTML={{ __html: TourDetails?.Highlights || "" }}
          />
        </div>
        <div className="package-inclueds">
          <h4>Package Includes</h4>
          <ul className="property-opti">
            {TourDetails?.tourfacilities &&
              TourDetails?.tourfacilities?.map((facility) => (
                <li>
                  <img src={facility?.Image} />
                  <span>{facility?.Name}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className="parckage-cities">
        <h5>Cities:</h5>
        <p>
          <img className="map-loc" src={MAP_IMAGE} />
          <span>{TourDetails?.TourCities}</span>
        </p>
      </div>
    </>
  );
};

export default PackageContent;
