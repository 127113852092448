import React, { useEffect } from "react";
import BredCrum from "../../components/BredCrum";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getWeekendGatewayDetail } from "./weekendGateWayDetailSlice";
import { Helmet } from "react-helmet";
import moment from "moment";

const WeekendGatewayDetailPage = () => {
  const dispatch = useDispatch();
  const { SubWGUrl } = useParams();
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  const WeekendGatewayDetail = useSelector(
    (state) => state.weekendGatewayDetail.WeekendGatewayDetail
  );

  useEffect(() => {
    const payload = {
      Url: SubWGUrl,
    };
    dispatch(getWeekendGatewayDetail(payload));
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>{WeekendGatewayDetail?.MetaTitle}</title>
        <meta
          name="description"
          content={WeekendGatewayDetail?.MetaDescription}
        />
        <meta name="keywords" content={WeekendGatewayDetail?.MetaKeywords} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <BredCrum />
      <div className="tour-detail-main gateway-detail-main">
        <div className="container container-1480">
          <div className="container">
            <div className="tour-detail-top">
              <div className="tour-detail-title">
                <h2>{WeekendGatewayDetail?.Name}</h2>
                <div className="detiltitle-under">
                  <div className="days-desgin">
                    <span>{WeekendGatewayDetail?.NoofDays} Days</span>{" "}
                    <strong>{WeekendGatewayDetail?.NoofNights} Nights</strong>
                  </div>
                  {/* <div className="share-wishlist">
                    <a href="#">
                      <img src="./images/share-ico.png" />
                    </a>{" "}
                    |
                    <a href="#">
                      <img src="./images/whislist.png" />
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="tour-detail-price">
                <span className="pricestaring">
                  SUPER DEAL PRICE <br />
                  <strong>₹{WeekendGatewayDetail?.PerCoupleRate}</strong>
                </span>
                <p>Per Couple</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="gateway-detail-top">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <div className="gateway-detail-pic">
                    <img src={WeekendGatewayDetail?.WeekendGatewayImage} />
                  </div>
                </div>

                {/* <div className="col-sm-12 col-md-6">
                  <div className="gateway-detail-pic">
                    <img src={WeekendGatewayDetail?.WeekendGatewayImage} />
                  </div>
                </div> */}
              </div>
            </div>

            <div className="gateway-highlights">
              <div className="package-higlite-inclued">
                <div className="package-higlite">
                  <h3>{WeekendGatewayDetail?.shortdescription}</h3>
                  <div className="d-flex align-items-center mt-4">
                    <h4 className="m-0">Valid Till</h4>
                    <h6 className="ms-4 mb-0">
                      {" "}
                      {moment(
                        WeekendGatewayDetail?.StartDate,
                        "DD/MM/YYYY"
                      ).format("DD MMM YYYY")}{" "}
                      to{" "}
                      {moment(
                        WeekendGatewayDetail?.EndDate,
                        "DD/MM/YYYY"
                      ).format("DD MMM YYYY")}
                    </h6>
                  </div>
                </div>

                <div className="package-inclueds">
                  <div className="w-450">
                    <div className="row d-flex">
                      <div className="col-6 text-start d-block">
                        <div>Special Rate</div>
                      </div>
                      <div className="col-6 text-end d-block">
                        <h5>₹ {WeekendGatewayDetail?.SpecialRate}</h5>
                      </div>
                    </div>

                    <div className="row d-flex">
                      <div className="col-6 text-start d-block">
                        <div>Extra Adult</div>
                      </div>
                      <div className="col-6 text-end d-block">
                        <h5>₹ {WeekendGatewayDetail?.ExtraAdultRate}</h5>
                      </div>
                    </div>

                    <div className="row d-flex">
                      <div className="col-6 text-start d-block">
                        <div>Extra Child without bed</div>
                      </div>
                      <div className="col-6 text-end d-block">
                        <h5>
                          ₹ {WeekendGatewayDetail?.ExtraChildRateWithoutBed}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gateway-detail">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="conclusiton-box">
                    <h5>INCLUSIONS</h5>
                    <div
                      className="conclusiton-box-data"
                      dangerouslySetInnerHTML={{
                        __html: WeekendGatewayDetail?.Inclusions,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="Tour-tab"
              dangerouslySetInnerHTML={{
                __html: WeekendGatewayDetail?.TourInfo,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WeekendGatewayDetailPage;
