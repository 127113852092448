import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendInquiry, sendInquiryToNeoDoveSoftware } from "../home/homeSlice";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const ContactUsForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inquiryLoading = useSelector((state) => state.home.inquiryLoading);

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Full Name is required"),
    EmailID: Yup.string()
      .email("Invalid email address")
      .required("Email Address is required"),
    MobileNo: Yup.number().required("Mobile Number is required"),
    Remarks: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      Name: "",
      EmailID: "",
      MobileNo: "",
      Remarks: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        ...values,
        MobileNo: values?.MobileNo?.toString(),
      };
      const neodovePayload = {
        name: values?.Name,
        mobile: values?.MobileNo,
        email: values?.EmailID,
        detail: values?.Remarks,
        detail2: "",
      };

      try {
        await dispatch(sendInquiry(payload)).unwrap();
        await dispatch(sendInquiryToNeoDoveSoftware(neodovePayload)).unwrap();
        resetForm();
        navigate("/thankyou/");
      } catch (error) {
        console.error("API error:", error);
      }
    },
  });

  return (
    <div class="contact-form">
      <h2>
        Love to hear from you,
        <br />
        Get in touch
      </h2>
      <form onSubmit={formik.handleSubmit}>
        <input
          type="text"
          placeholder="Your Full Name"
          name="Name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.Name}
          className={
            formik.touched.Name && formik.errors.Name ? "error-border" : ""
          }
        />
        <input
          name="EmailID"
          type="text"
          placeholder="Email Address"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.EmailID}
          className={
            formik.touched.EmailID && formik.errors.EmailID
              ? "error-border"
              : ""
          }
        />
        <input
          type="number"
          name="MobileNo"
          placeholder="Mobile Number"
          onChange={formik.handleChange}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 10);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.MobileNo}
          className={
            formik.touched.MobileNo && formik.errors.MobileNo
              ? "error-border"
              : ""
          }
        />
        <textarea
          placeholder="Write your message here..."
          name="Remarks"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.Remarks}
          className={
            formik.touched.Remarks && formik.errors.Remarks
              ? "error-border"
              : ""
          }
        />
        <input
          disabled={inquiryLoading}
          className="btn btn-fill"
          type="submit"
          value={inquiryLoading ? "Submiting Details" : "Send"}
        />
      </form>
    </div>
  );
};

export default ContactUsForm;
