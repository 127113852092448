import React from "react";

const NoDataFound = () => {
  return (
    <div className="no-data-found">
      <h4>No data found</h4>
    </div>
  );
};

export default NoDataFound;
