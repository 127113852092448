import React from "react";
import { useSelector } from "react-redux";
import EYE_ICON from "../../../../assets/images/EYE_ICON.png";
import { Element } from "react-scroll";

const TourDocuments = () => {
  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);

  const handleOpenDoc = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Element
      name="Documents-tab"
      // className="accordion-date-body"
      style={{
        textAlign: "justify",
        padding: 15,
        boxShadow: "0 0 10px lightgray",
        borderRadius: 20,
        marginBottom: 30,
      }}
    >
      <h6
        style={{
          background: "antiquewhite",
          padding: 10,
          borderRadius: 10,
          fontWeight: 700,
          marginBottom: 10,
        }}
      >
        Documents
      </h6>
      <ul>
        {TourDetails?.tourDocuments?.map((doc) => (
          <li className="accordion-date">
            <p
              style={{
                display: "inline-block",
                marginRight: "20px",
                fontWeight: 500,
              }}
            >
              {doc?.Title}
            </p>
            <img
              className="eye-loc"
              src={EYE_ICON}
              onClick={() => handleOpenDoc(doc?.Documents)}
            />
          </li>
        ))}
      </ul>
    </Element>
    // <div className="accordion-date-body">
    //   <ul>
    //     {TourDetails?.tourDocuments?.map((doc) => (
    //       <li className="accordion-date">
    //         <p
    //           style={{
    //             display: "inline-block",
    //             marginRight: "20px",
    //             fontWeight: 500,
    //           }}
    //         >
    //           {doc?.Title}
    //         </p>
    //         <img
    //           className="eye-loc"
    //           src={EYE_ICON}
    //           onClick={() => handleOpenDoc(doc?.Documents)}
    //         />
    //       </li>
    //     ))}
    //   </ul>
    // </div>
  );
};

export default TourDocuments;
