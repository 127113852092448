import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getblogDatail = createAsyncThunk(
    "Blog/BlogFindByURL",
    async (payload) => {
        const response = await axiosApiInstance.post(
            `${API_APP_URL}/Blog/BlogFindByURL`,
            payload
        );
        const BlogDetail = response.data?.Data || [];
        const BlogDetailBanner = response.data?.BannerImage || "";
        return { BlogDetail, BlogDetailBanner };
    }
);

const initialState = {
    loading: false,
    error: "",
    BlogDetailsData: {
        BlogDetail: {},
    },
}

const blogDetailSlice = createSlice({
    name: "blogDetailSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getblogDatail.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getblogDatail.fulfilled, (state, action) => {
            state.loading = false;
            state.BlogDetailsData = action.payload;
            state.error = "";
        });
        builder.addCase(getblogDatail.rejected, (state, action) => {
            state.loading = false;
            state.BlogDetailsData = [];
            state.error = action.error.message || "Something went wrong";
        });
    }
})

export default blogDetailSlice.reducer;