import React from "react";
import { useSelector } from "react-redux";
import { Element } from "react-scroll";

const OverView = () => {
  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);

  return (
    // <div
    //   className="tab-pane fade show active"
    //   id="Overview"
    //   role="tabpanel"
    //   aria-labelledby="Overview-tab"
    //   dangerouslySetInnerHTML={{ __html: TourDetails?.Overview || "" }}
    // />
    <Element
      className="tab-pane fade show active"
      id="Overview"
      name="overview-tab"
      role="tabpanel"
      aria-labelledby="Overview-tab"
      style={{
        textAlign: "justify",
        padding: 15,
        boxShadow: "0 0 10px lightgray",
        borderRadius: 20,
        marginBottom: 30,
      }}
      dangerouslySetInnerHTML={{ __html: TourDetails?.Overview || "" }}
    />
  );
};

export default OverView;
