import React, { useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getContactUsDetail } from "./contactSlice";
import ContactUsForm from "./ContactUsForm";
import { Helmet } from "react-helmet";

const ContactUsPage = () => {
  const dispatch = useDispatch();
  const ContactUsData = useSelector((state) => state.contact.ContactUsData);

  useEffect(() => {
    dispatch(getContactUsDetail());
  }, []);

  return (
    <>
      <Helmet>
        <title>{ContactUsData?.MetaTitle}</title>
        <meta name="description" content={ContactUsData?.MetaDescription} />
        <meta name="keywords" content={ContactUsData?.MetaKeywords} />
        <link rel="canonical" href={ContactUsData?.Canonical} />
      </Helmet>

      <PageTitle
        BannerTitle={"Contact Us"}
        BannerImage={ContactUsData?.BannerImage}
      />
      <div class="contactpage-info">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <ContactUsForm />
            </div>
            <div
              class="col-sm-12 col-md-6"
              dangerouslySetInnerHTML={{
                __html: ContactUsData?.ContactDetails,
              }}
            />
          </div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: ContactUsData?.Branch,
        }}
      />
    </>
  );
};

export default ContactUsPage;
