import React from "react";
import PAGE_TITLE_BG from "../../assets/images/home-banner.jpg";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const CHolidaysTitle = () => {
  const { pathname } = useLocation();

  // const { CHolidaysBanner } = useSelector(
  //   (state) => state.customizeHolidays.CustomizeHolidaysData
  // );

  const pathSegments = pathname.split("/").filter((segment) => segment !== "");

  return (
    <div
      className="pagetitle customize-Holiday"
      style={{
        background: `url(${PAGE_TITLE_BG})`,
        // background: `url(${CHolidaysBanner})`,
      }}
    >
      <div className="container">
        <h1>Customize Holiday</h1>
        <div className="breadrm">
          <Link to="/">Home</Link> {">"}
          {pathSegments && <span>{pathSegments[pathSegments.length - 1]}</span>}
        </div>
      </div>
    </div>
  );
};

export default CHolidaysTitle;
