import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFooterDetail } from "./footerSlice";

const Footer = () => {
  const dispatch = useDispatch();
  const FooterData = useSelector((state) => state.footer.FooterData);

  useEffect(() => {
    dispatch(getFooterDetail());
  }, []);

  return <footer dangerouslySetInnerHTML={{ __html: FooterData }} />;
};

export default Footer;
