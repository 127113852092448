import React from "react";
import { useSelector } from "react-redux";
import NewsListCard from "./NewsListCard";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import NoDataFound from "../../components/common/NoDataFound";

const NewsList = () => {
  const NewsListData = useSelector((state) => state.newsList.NewsListData);
  const loading = useSelector((state) => state.newsList.loading);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="col-sm-12 col-md-8">
      {!loading && !NewsListData?.Data ? (
        <NoDataFound />
      ) : (
        <>
          {NewsListData?.Data?.map((news) => (
            <NewsListCard news={news} />
          ))}
        </>
      )}
    </div>
  );
};

export default NewsList;
