import React from "react";
// import RIGHT_ARROW from "../../assets/images/arrow-rigt-blue.png";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { setTourListFilter } from "./packageListSlice";

const PackageListPagination = ({ pageCount }) => {
  const dispatch = useDispatch();
  const TourListFilter = useSelector(
    (state) => state.packageList.TourListFilter
  );

  const totalPages = Math.ceil(pageCount / TourListFilter?.PageSize);

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel=">"
      onPageChange={({ selected }) => {
        return dispatch(
          setTourListFilter({ ...TourListFilter, PageIndex: selected + 1 })
        );
      }}
      pageRangeDisplayed={1}
      pageCount={totalPages}
      forcePage={TourListFilter?.PageIndex - 1}
      previousLabel="<"
      containerClassName="pagination"
      activeClassName="active"
      renderOnZeroPageCount={null}
    />
  );
};

export default PackageListPagination;
