import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAboutUsDetail } from "./aboutSlice";
import { Helmet } from "react-helmet";
import PageTitle from "../../components/PageTitle";
import { useLocation } from "react-router-dom";

const AboutUsPage = () => {
  const dispatch = useDispatch();
  const AboutUsData = useSelector((state) => state.about.AboutUsData);
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  useEffect(() => {
    dispatch(getAboutUsDetail());
  }, []);

  return (
    <>
      <Helmet>
        <title>{AboutUsData?.MetaTitle}</title>
        <meta name="description" content={AboutUsData?.MetaDescription} />
        <meta name="keywords" content={AboutUsData?.MetaKeywords} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <PageTitle
        BannerTitle={"About Us"}
        BannerImage={AboutUsData?.BannerImage}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: AboutUsData?.Template,
        }}
      />
    </>
  );
};

export default AboutUsPage;
