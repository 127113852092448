import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getAllDomesticDest = createAsyncThunk(
  "DomesticDetinationList/GetAll",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_APP_URL}/HomePage/GetDomesticDetinationList`
    );
    const DomesticDestList = response.data?.Data || [];
    const DomesticDestListSeo = {
      MetaDescription: response.data?.MetaDescription,
      MetaTitle: response.data?.MetaTitle,
      MetaKeywords: response.data?.MetaKeywords,
    };
    return { DomesticDestList, DomesticDestListSeo };
  }
);

const initialState = {
  loading: false,
  error: "",
  DomesticDestList: [],
  DomesticDestListSeo: {},
};

const domesticDestSlice = createSlice({
  name: "domesticDestSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllDomesticDest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllDomesticDest.fulfilled, (state, action) => {
      state.loading = false;
      state.DomesticDestList = action.payload.DomesticDestList;
      state.DomesticDestListSeo = action.payload.DomesticDestListSeo;
      state.error = "";
    });
    builder.addCase(getAllDomesticDest.rejected, (state, action) => {
      state.loading = false;
      state.DomesticDestList = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default domesticDestSlice.reducer;
