import React, { useEffect } from "react";
import CHolidaysTitle from "./CHolidaysTitle";
import PersionlizedPackages from "./PersionlizedPackages";
import IndianGateWays from "./IndianGateWays";
import WeekendGateways from "./WeekendGateways";
import { useDispatch, useSelector } from "react-redux";
import { getCustomizeHolidays } from "./customizeHolidaySlice";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const CHolidaysListPage = () => {
  const dispatch = useDispatch();
  const { SeoPoints } = useSelector(
    (state) => state.customizeHolidays.CustomizeHolidaysData
  );
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  useEffect(() => {
    dispatch(getCustomizeHolidays());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{SeoPoints?.MetaTitle}</title>
        <meta name="description" content={SeoPoints?.MetaDescription} />
        <meta name="keywords" content={SeoPoints?.MetaKeywords} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <CHolidaysTitle />
      <PersionlizedPackages />
      <IndianGateWays />
      <WeekendGateways />
    </>
  );
};

export default CHolidaysListPage;
