import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getAllNewsDetail } from "./newsDetailSlice";
import NewsDetailSection from "./NewsDetailSection";
import PageTitle from "../../components/PageTitle";
import { useLocation, useParams } from "react-router-dom";

const NewsDetailPage = () => {
  const dispatch = useDispatch();
  const { NewsDetails, NewsDetailBanner } = useSelector(
    (state) => state.newsDetail.NewsDetailsData
  );
  const { newsUrl } = useParams();
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  useEffect(() => {
    const payload = {
      NewsURL: newsUrl,
    };
    dispatch(getAllNewsDetail(payload));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{NewsDetails?.MetaTitle}</title>
        <meta name="description" content={NewsDetails?.MetaDescription} />
        <meta name="keywords" content={NewsDetails?.MetaKeywords} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <PageTitle BannerTitle={"News Detail"} BannerImage={NewsDetailBanner} />
      <NewsDetailSection />
    </>
  );
};

export default NewsDetailPage;
