import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const WeekendGatewayTabing = () => {
  const STAR_TABS = ["3 Star", "4 Star", "5 Star"];
  const [activeStarTab, setStarActiveTab] = useState(STAR_TABS[0]);
  const navigate = useNavigate();

  const WeekendGatewayData = useSelector(
    (state) => state.weekendGateway.WeekendGatewayData
  );

  const handleNavigate = (url) => {
    if (url) {
      navigate(`${url}/`);
    }
  };

  const handleStarTabClick = (tab) => {
    setStarActiveTab(tab);
  };

  const getActiveStarTab = (tab) => {
    const starNumber = tab.split(" ")[0];
    const spelledOutNumber =
      Number(starNumber) === 3
        ? "Three"
        : Number(starNumber) === 4
        ? "Four"
        : Number(starNumber) === 5
        ? "Five"
        : "";
    return `${spelledOutNumber}Star`;
  };

  const renderPackages = () => {
    const packagesData = {
      ThreeStar: WeekendGatewayData?.URLWiseDestinationList?.ThreeStar || [],
      FourStar: WeekendGatewayData?.URLWiseDestinationList?.FourStar || [],
      FiveStar: WeekendGatewayData?.URLWiseDestinationList?.FiveStar || [],
    };

    const activePackages = packagesData[`${getActiveStarTab(activeStarTab)}`];

    if (!activePackages) {
      return (
        <div className="col-12">
          <h6 className="text-center">No Weekend Gateways Available.</h6>
        </div>
      );
    }

    return activePackages.map((pckg, index) => (
      <div key={index} className="col-lg-4 col-md-6 col-sm-12">
        <div className="similer-packages">
          <div className="similarproduct-pic">
            <img
              className="cursor-pointer"
              onClick={() => handleNavigate(pckg?.PageURL)}
              src={pckg?.WeekendGatewayImage}
            />
            <div className="days-desgin">
              <span>{pckg?.NoofDays} Days</span>{" "}
              <strong>{pckg?.NoofNights} Nights</strong>
            </div>
          </div>
          <div className="similarproduct-data">
            <h6
              className="hoverable-title"
              onClick={() => handleNavigate(pckg?.PageURL)}
            >
              {pckg?.Name}
            </h6>
            <div className="price-similar">
              <strong>Package Starting From:</strong>
              <span>₹{pckg?.TotalPrice}</span>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="cusomize-holiday-tabbing">
        <h2>Weekend Gateways</h2>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          {STAR_TABS.map((tab, index) => (
            <li key={index} className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeStarTab === tab ? "active" : ""}`}
                onClick={() => handleStarTabClick(tab)}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="cusomize-holiday">
              <div className="container">
                <div className="row">{renderPackages()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeekendGatewayTabing;
