import React, { useEffect } from "react";
import BANNERIMAGE from "../../assets/images/pune.jpg";
import FOODBANNERIMAGE from "../../assets/images/food-banner-pune.jpg";
import InquirySectionForPune from "./InquirySectionForPune";
import { useDispatch } from "react-redux";
import { getHomeData } from "../home/homeSlice";
import TopIndianDestinationForAddressPage from "./TopIndianDestinationForAddressPage";
import { MdOutlineEmojiTransportation } from "react-icons/md";
import { LiaHotelSolid } from "react-icons/lia";
import { GrUserManager } from "react-icons/gr";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../_hooks/useWindowSize";

const Pune = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  useEffect(() => {
    dispatch(getHomeData());
  }, []);

  return (
    <>
      <Helmet>
        <title>{"Top Travel Agent in Pune | Ajay Modi Travels Pune"}</title>
        <meta
          name="description"
          content={
            "Looking for a reliable travel agent in Pune? Ajay Modi Travels offers personalized travel solutions, exceptional service, and pure veg & Jain food prepared by our Maharaj"
          }
        />
        <meta
          name="keywords"
          content={
            "Travel Agent in Pune, Ajay Modi Travels, Pune travel agency, personalized travel services, Pune tour packages"
          }
        />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <div className="container-fluid p-0 pune-image-container">
        {width >= 992 && (
          <img
            src={BANNERIMAGE}
            alt=""
            style={{ width: "100%" }}
            className="pune-image"
          />
        )}
      </div>
      <InquirySectionForPune />
      <div className="container-fluid p-0">
        <img src={FOODBANNERIMAGE} alt="" style={{ width: "100%" }} />
      </div>
      <div className="container">
        <div className="row" style={{ padding: "80px 0" }}>
          <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
            <div className="address-page-card">
              <div
                className="d-flex align-items-center mb-3"
                style={{ color: "#F26A2F" }}
              >
                <GrUserManager style={{ fontSize: 50, paddingRight: 10 }} />

                <h3 style={{ margin: 0 }}>Dedicated Tour Manager</h3>
              </div>
              <p style={{ textAlign: "justify", fontSize: 14 }}>
                Your journey with Ajay Modi Travels is led by expert tour
                managers who are not only well-versed in the destinations but
                also have in-depth knowledge of the local culture, customs, and
                attractions. From Pune to across India and beyond, our tour
                managers ensure a smooth, enjoyable, and memorable experience
                for every traveler.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
            <div className="address-page-card">
              <div
                className="d-flex align-items-center mb-3"
                style={{ color: "#F26A2F" }}
              >
                <MdOutlineEmojiTransportation
                  style={{ fontSize: 50, paddingRight: 10 }}
                />
                <h3 style={{ margin: 0 }}>Reliable Transportation</h3>
              </div>
              <p style={{ textAlign: "justify", fontSize: 14 }}>
                We prioritize your comfort and convenience by providing
                well-maintained, modern, and safe transportation options.
                Whether it’s a private vehicle, luxury coach, or local
                transport, we ensure that you travel in style, safety, and
                comfort at all times.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
            <div className="address-page-card">
              <div
                className="d-flex align-items-center mb-3"
                style={{ color: "#F26A2F" }}
              >
                <LiaHotelSolid style={{ fontSize: 50, paddingRight: 10 }} />
                <h3 style={{ margin: 0 }}>Handpicked Hotels</h3>
              </div>
              <p style={{ textAlign: "justify", fontSize: 14 }}>
                Accommodation is a key part of any journey, and we partner with
                trusted hotels that guarantee comfort, cleanliness, and quality.
                From luxurious stays to budget-friendly options, we provide the
                best possible hotels that match your preferences and make your
                stay enjoyable.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          background:
            "linear-gradient(45deg, rgb(255 202 180), rgb(255 211 146))",
          padding: "80px 0",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="text-center mb-5">
                Why We Are the Best Travel Agents in Pune?
              </h3>
              <p style={{ textAlign: "justify" }}>
                At Ajay Modi Travels, we bring years of expertise and a
                commitment to personalized service to our Pune branch. Known for
                our carefully curated vacation packages and attention to detail,
                we ensure every journey is a seamless and memorable experience.
                Whether you're planning a relaxing getaway, an adventurous
                exploration, or a cultural tour, our dedicated team in Pune is
                here to make your travel dreams a reality. Trust us to be your
                reliable travel partner, delivering unmatched service and the
                best travel experiences in Pune.
              </p>
            </div>
          </div>
        </div>
      </div>
      <TopIndianDestinationForAddressPage />
    </>
  );
};

export default Pune;
