import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import accessibility from "highcharts/modules/accessibility";
import LoadingSpinner from "../../../components/common/LoadingSpinner";
import { MAP_STATE_ARRAY } from "../../../common/constantArray";
import { useDispatch } from "react-redux";
import { getCitiesByDestId } from "../homeSlice";
highchartsMap(Highcharts);
accessibility(Highcharts);

const IndiaData = [
  ["madhya pradesh", 8],
  ["uttar pradesh", 9],
  ["karnataka", 10],
  ["nagaland", 11],
  ["bihar", 12],
  ["odisha", 13],
  ["lakshadweep", 14],
  ["sikkim", 15],
  ["andaman and nicobar", 16],
  ["assam", 17],
  ["west bengal", 18],
  ["puducherry", 19],
  ["daman and diu", 20],
  ["gujarat", 21],
  ["rajasthan", 22],
  ["dadara and nagar havelli", 23],
  ["chhattisgarh", 24],
  ["tamil nadu", 25],
  ["chandigarh", 26],
  ["punjab", 27],
  ["haryana", 28],
  ["andhra pradesh", 29],
  ["maharashtra", 30],
  ["jammu and kashmir", 31],
  ["himachal pradesh", 32],
  ["meghalaya", 33],
  ["kerala", 34],
  ["telangana", 35],
  ["mizoram", 36],
  ["tripura", 37],
  ["manipur", 38],
  ["arunanchal pradesh", 39],
  ["jharkhand", 40],
  ["goa", 41],
  ["nct of delhi", 42],
  ["uttarakhand", 43],
];

const WorldData = [
  ["gl", 9],
  ["us", 10],
  ["ca", 11],
  ["mx", 12],
  ["gb", 13],
  ["fr", 14],
  ["de", 15],
  ["jp", 16],
  ["cn", 17],
  ["in", 18],
  ["br", 19],
  ["au", 20],
  ["co", 10],
  ["pe", 11],
  ["py", 12],
  ["ar", 13],
  ["cl", 14],
  ["gr", 15],
  ["dz", 16],
  ["ml", 17],
  ["sa", 18],
  ["sd", 19],
  ["cf", 20],
  ["cd", 21],
  ["mz", 22],
  ["bw", 23],
  ["za", 24],
  ["kz", 25],
  ["uz", 26],
  ["ir", 27],
  ["ru", 28],
  ["jp", 29],
  ["tw", 30],
  ["ph", 31],
  ["pw", 32],
  ["id", 33],
  ["nr", 34],
  ["sb", 35],
  ["fj", 36],
  ["nz", 37],
];

const ExploreIndiaMap = ({ activeMap }) => {
  const [indiaTopology, setIndiaTopology] = useState();
  const [worldTopology, setWorldTopology] = useState();
  const [mapWidth, setMapWidth] = useState(
    window.innerWidth > 576 ? 576 : window.innerWidth
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setMapWidth(window.innerWidth > 576 ? 576 : window.innerWidth - 20);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    (async () => {
      setIndiaTopology(
        await fetch(
          // "https://code.highcharts.com/mapdata/countries/in/in-all.topo.json"
          "https://code.highcharts.com/mapdata/countries/in/custom/in-all-disputed.topo.json"
        ).then((response) => response.json())
      );

      setWorldTopology(
        await fetch(
          "https://code.highcharts.com/mapdata/custom/world-highres.geo.json"
        ).then((response) => response.json())
      );
    })();
  }, []);

  const handleMapClick = (event) => {
    const point = event.point;
    const clickedItem = MAP_STATE_ARRAY.find(
      (item) =>
        item.label.trim().toLowerCase() === point.name.trim().toLowerCase()
    );

    if (clickedItem?.value) {
      dispatch(getCitiesByDestId({ DestinationID: +clickedItem?.value }));
    }
  };

  if (!indiaTopology && !worldTopology) {
    return <LoadingSpinner />;
  }

  return (
    <HighchartsReact
      key={activeMap}
      constructorType={"mapChart"}
      highcharts={Highcharts}
      options={{
        chart: {
          map: activeMap === "Zone" ? worldTopology : indiaTopology,
          height: 500,
          width: mapWidth,
          // minWidth: 350,
          // maxWidth: 576,
          backgroundColor: "transparent",
        },
        mapNavigation: {
          enabled: true,
        },
        colorAxis: {
          min: 0,
        },
        accessibility: {
          enabled: true,
        },
        series: [
          {
            data: activeMap === "Zone" ? WorldData : IndiaData,
            name: activeMap === "Zone" ? "Zone" : "India",
            states: {
              hover: {
                color: "#2ebad3",
              },
            },
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
            events: {
              click: handleMapClick,
            },
          },
        ],
      }}
    />
  );
};

export default ExploreIndiaMap;
