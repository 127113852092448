import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWeekendGatewayData } from "./weekenGatewaySlice";
import PageTitle from "../../components/PageTitle";
import { Helmet } from "react-helmet";
import WeekendGatewayTabing from "./WeekendGatewayTabing";
import { useLocation, useParams } from "react-router-dom";
import { getOrignalURL } from "../../common/helpers";

const WeekendGatewaysPage = () => {
  const dispatch = useDispatch();
  const { WGUrl } = useParams();
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  const WeekendGatewayData = useSelector(
    (state) => state.weekendGateway.WeekendGatewayData
  );

  useEffect(() => {
    dispatch(getWeekendGatewayData({ DestinationURL: getOrignalURL(WGUrl) }));
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>{WeekendGatewayData?.MetaTitle}</title>
        <meta
          name="description"
          content={WeekendGatewayData?.MetaDescription}
        />
        <meta name="keywords" content={WeekendGatewayData?.MetaKeywords} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <PageTitle
        BannerTitle={"Weekand Gateway"}
        BannerImage={WeekendGatewayData?.WGstaticBanner}
      />
      <WeekendGatewayTabing />
    </>
  );
};

export default WeekendGatewaysPage;
