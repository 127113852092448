import React from "react";
import { useSelector } from "react-redux";
import { Element } from "react-scroll";

const InclusionExclusion = () => {
  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);

  return (
    <Element
      className="tab-pane fade show active"
      id="Inclusions"
      name="Inclusions-Exclusions-tab"
      role="tabpanel"
      aria-labelledby="Inclusions-tab"
    >
      <div className="conclusiton-box">
        <h5>INCLUSIONS</h5>
        <div
          className="conclusiton-box-data"
          dangerouslySetInnerHTML={{ __html: TourDetails?.Inclusions || "" }}
        />
      </div>
      <div className="conclusiton-box red-box">
        <h5>EXCLUSIONS</h5>
        <div
          className="conclusiton-box-data"
          dangerouslySetInnerHTML={{ __html: TourDetails?.Exclusions || "" }}
        />
      </div>
    </Element>
    // <div
    //   className="tab-pane fade show active"
    //   id="Inclusions"
    //   role="tabpanel"
    //   aria-labelledby="Inclusions-tab"
    // >
    //   <div className="conclusiton-box">
    //     <h5>INCLUSIONS</h5>
    //     <div
    //       className="conclusiton-box-data"
    //       dangerouslySetInnerHTML={{ __html: TourDetails?.Inclusions || "" }}
    //     />
    //   </div>
    //   <div className="conclusiton-box red-box">
    //     <h5>EXCLUSIONS</h5>
    //     <div
    //       className="conclusiton-box-data"
    //       dangerouslySetInnerHTML={{ __html: TourDetails?.Exclusions || "" }}
    //     />
    //   </div>
    // </div>
  );
};

export default InclusionExclusion;
