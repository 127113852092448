import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getAllNewsDetail = createAsyncThunk(
  "News/NewsFindByID",
  async (payload) => {
    const response = await axiosApiInstance.post(
      `${API_APP_URL}/News/NewsFindByID`,
      payload
    );
    const NewsDetails = response.data?.Data || {};
    const NewsDetailBanner = response.data?.BannerImage || "";
    return { NewsDetails, NewsDetailBanner };
  }
);

const initialState = {
  loading: false,
  error: "",
  NewsDetailsData: {
    NewsDetails: {},
    BannerImage: "",
  },
};

const newsDetailSlice = createSlice({
  name: "newsDetailSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllNewsDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllNewsDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.NewsDetailsData = action.payload;
      state.error = "";
    });
    builder.addCase(getAllNewsDetail.rejected, (state, action) => {
      state.loading = false;
      state.NewsDetailsData = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default newsDetailSlice.reducer;
