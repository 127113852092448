import React from "react";
import { useSelector } from "react-redux";
import { Element } from "react-scroll";

const TourDates = () => {
  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);

  return (
    <Element
      className="tab-pane fade show active"
      id="Itinerary"
      name="TourDates-tab"
      role="tabpanel"
      style={{
        textAlign: "justify",
        padding: 15,
        boxShadow: "0 0 10px lightgray",
        borderRadius: 20,
        marginBottom: 30,
      }}
      aria-labelledby="Itinerary-tab"
    >
      {TourDetails?.tourdates?.map((date) => (
        <div className="mb-4">
          <div
            style={{
              background: "antiquewhite",
              padding: 10,
              borderRadius: 10,
              fontWeight: 600,
              marginBottom: 10,
            }}
          >
            {date?.MonthName}
          </div>
          <div>
            <ul
              style={{
                listStyle: "none",
                margin: 0,
                padding: 0,
                display: "flex",
                gap: 10,
                flexWrap: "wrap",
              }}
            >
              {date?.tourdateData?.map((innerDate) => (
                <li
                  style={{
                    padding: 10,
                    borderRadius: 5,
                    // background: "#eeeded",
                    boxShadow: "inset 1px 1px 5px lightgray",
                  }}
                >
                  {innerDate?.TourDate}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
      {/* <div className="accordion" id="accordionExample">
        {TourDetails?.tourdates?.map((date, index) => (
          <div className="accordion-date-item" key={date?.Month}>
            <h2
              className="accordion-header month-header"
              id={"heading" + date?.Month}
            >
              <button
                className={`accordion-date-button ${
                  index === 0 ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={"#collapse" + date?.Month}
                aria-expanded={index === 0 ? "true" : "false"}
                aria-controls={"collapse" + date?.Month}
              >
                <div className="days-left text-start w-100">
                  <span>
                    <strong>{date?.MonthName}</strong>
                  </span>
                </div>
              </button>
            </h2>
            <div
              id={"collapse" + date?.Month}
              className={`accordion-collapse collapse ${
                index === 0 ? "show" : ""
              }`}
              aria-labelledby={"heading" + date?.Month}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-date-body">
                <ul>
                  {date?.tourdateData?.map((innerDate) => (
                    <li className="accordion-date">{innerDate?.TourDate}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div> */}
    </Element>
    // <div
    //   className="tab-pane fade show active"
    //   id="Itinerary"
    //   role="tabpanel"
    //   aria-labelledby="Itinerary-tab"
    // >
    //   <div className="accordion" id="accordionExample">
    //     {TourDetails?.tourdates?.map((date, index) => (
    //       <div className="accordion-date-item" key={date?.Month}>
    //         <h2
    //           className="accordion-header month-header"
    //           id={"heading" + date?.Month}
    //         >
    //           <button
    //             className={`accordion-date-button ${
    //               index === 0 ? "" : "collapsed"
    //             }`}
    //             type="button"
    //             data-bs-toggle="collapse"
    //             data-bs-target={"#collapse" + date?.Month}
    //             aria-expanded={index === 0 ? "true" : "false"}
    //             aria-controls={"collapse" + date?.Month}
    //           >
    //             <div className="days-left text-start w-100">
    //               <span>
    //                 <strong>{date?.MonthName}</strong>
    //               </span>
    //             </div>
    //           </button>
    //         </h2>
    //         <div
    //           id={"collapse" + date?.Month}
    //           className={`accordion-collapse collapse ${
    //             index === 0 ? "show" : ""
    //           }`}
    //           aria-labelledby={"heading" + date?.Month}
    //           data-bs-parent="#accordionExample"
    //         >
    //           <div className="accordion-date-body">
    //             <ul>
    //               {date?.tourdateData?.map((innerDate) => (
    //                 <li className="accordion-date">{innerDate?.TourDate}</li>
    //               ))}
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
};

export default TourDates;
