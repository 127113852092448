import React from "react";
import BLOG_BG from "../../assets/images/blog-bg.jpg";
import { useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const IndianGateWays = () => {
  const { CHolidaysData } = useSelector(
    (state) => state.customizeHolidays.CustomizeHolidaysData
  );

  const settings = {
    className: "discover-slick",
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const handleNavigate = (tour) => {
    if (tour?.TourURL) {
      navigate(`/package/${tour?.TourURL}/`, {
        state: {
          tourData: {
            RateType: tour?.RateType,
            NoOfNights: tour?.Nights,
            RoomTypeID: tour?.RoomTypeID,
          },
        },
      });
    }
  };

  return (
    <div className="blog-section full-section relative">
      <div className="bg-sections">
        <img src={BLOG_BG} />
      </div>
      <div className="container relative">
        <h2 className="text-center">Indian Gateways</h2>
        {CHolidaysData?.IndianGateways?.length > 3 ? (
          // <>
          <Slider {...settings}>
            {CHolidaysData?.IndianGateways?.map((dest) => (
              <div className="item">
                <div className="similarproduct-pic">
                  <img
                    className="cursor-pointer"
                    onClick={() => handleNavigate(dest)}
                    src={dest?.TourImage}
                  />
                  <div className="days-desgin">
                    <span>{dest?.Nights + 1} Days</span>{" "}
                    <strong>{dest?.Nights} Nights</strong>
                  </div>
                </div>
                <div className="similarproduct-data">
                  <h6
                    className="hoverable-title"
                    onClick={() => handleNavigate(dest)}
                  >
                    {dest?.TourName}
                  </h6>
                  <div className="price-similar">
                    <strong>Package Starting From:</strong>
                    <span>₹{dest?.TotalPrice}</span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <div className="row gap-3 indian-gateways-div">
            {/* <OwlCarousel
          className="owl-carousel indian-gateways-owl owl-theme arrow-top-right"
          loop
          margin={25}
          dots={false}
          nav
          autoplay={true}
          autoplayTimeout={4000}
          autoplayHoverPause={true}
          responsive={{
            0: {
              items: 1,
            },
            600: {
              items: 2,
            },
            1000: {
              items: 3,
            },
          }}
        > */}
            {CHolidaysData?.IndianGateways?.map((dest) => (
              <div className="item col-md-4 col-sm-6">
                <div className="similarproduct-pic">
                  <img
                    className="cursor-pointer"
                    onClick={() => handleNavigate(dest)}
                    src={dest?.TourImage}
                  />
                  <div className="days-desgin">
                    <span>{dest?.Nights + 1} Days</span>{" "}
                    <strong>{dest?.Nights} Nights</strong>
                  </div>
                </div>
                <div className="similarproduct-data">
                  <h6
                    className="hoverable-title"
                    onClick={() => handleNavigate(dest)}
                  >
                    {dest?.TourName}
                  </h6>
                  <div className="price-similar">
                    <strong>Package Starting From:</strong>
                    <span>₹{dest?.TotalPrice}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default IndianGateWays;
