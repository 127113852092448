import React from "react";
import { Link, useLocation } from "react-router-dom";
import BANNER_DEFAULT_IMAGE from "../assets/images/placeholderImage.jpg";

const PageTitle = ({ BannerImage, BannerTitle = "Ajay Modi Travels" }) => {
  const { pathname } = useLocation();
  const pathSegments = pathname.split("/").filter((segment) => segment !== "");

  return (
    <div
      className="pagetitle"
      style={{
        background: `url(${BannerImage || BANNER_DEFAULT_IMAGE})`,
      }}
    >
      <div className="container">
        <h1>{BannerTitle}</h1>
        <div className="breadrm">
          <Link to="/">Home</Link> {">"}
          {pathSegments && (
            <span>
              {pathSegments[pathSegments.length - 1]
                ?.split("-")
                ?.join(" ")
                ?.toUpperCase()}
            </span>
          )}
          {/* {pathSegments.map((segment, index) => (
            <>
              <span key={index}>{segment}</span>
              {pathSegments.length > index + 1 ? ">" : ""}
            </>
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
