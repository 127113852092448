import React from "react";
import PageTitle from "../../components/PageTitle";
import { Link } from "react-router-dom";

const ThankYouPage = () => {
  return (
    <>
      <PageTitle BannerTitle={"Thank You"} BannerImage={""} />
      <div className="thankyou-wrapper">
        <h1>
          <img
            src="http://www.websiteimages.careerline.in/uploads/thankyou.png"
            alt="Thanks"
          />
        </h1>
        <p>for contacting us, we will get in touch with you soon...</p>
        <Link to="/">Back to home</Link>
        <div className="clr"></div>
      </div>
    </>
  );
};

export default ThankYouPage;
