export const HEADER_DATA = [
  {
    Title: "Home",
    Url: "/",
    Items: [],
  },
  {
    Title: "India",
    Url: "",
    Items: [
      {
        Title: "ANDAMAN AND NICOBAR",
        Url: "andaman-and-nicobar",
        Items: [
          {
            Title: "PORT BLAIR",
            Url: "port-blair",
            Items: [],
          },
          {
            Title: "HAVELOCK",
            Url: "havelock",
            Items: [],
          },
          {
            Title: "NEIL ISLAND",
            Url: "neil-island",
            Items: [],
          },
        ],
      },
      {
        Title: "CHARDHAM",
        Url: "chardham",
        Items: [
          {
            Title: "BARKOT",
            Url: "barkot-",
            Items: [],
          },
          {
            Title: "UTTARKASHI",
            Url: "uttarkashi",
            Items: [],
          },
          {
            Title: "GUPTKASHI",
            Url: "guptkashi",
            Items: [],
          },
          {
            Title: "KEDARNATH",
            Url: "kedarnath",
            Items: [],
          },
          {
            Title: "BADRINATH",
            Url: "badrinath",
            Items: [],
          },
        ],
      },
      {
        Title: "HIMACHAL",
        Url: "himachal",
        Items: [
          {
            Title: "AMRITSAR",
            Url: "amritsar",
            Items: [],
          },
          {
            Title: "SHIMLA",
            Url: "shimla",
            Items: [],
          },
          {
            Title: "MANALI",
            Url: "manali",
            Items: [],
          },
          {
            Title: "DALHOUSIE",
            Url: "dalhousie",
            Items: [],
          },
          {
            Title: "DHARAMSHALA",
            Url: "dharamshala-",
            Items: [],
          },
        ],
      },
      {
        Title: "KASHMIR",
        Url: "kashmir",
        Items: [
          {
            Title: "PAHALGAM",
            Url: "pahalgam",
            Items: [],
          },
          {
            Title: "SRINAGAR",
            Url: "srinagar",
            Items: [],
          },
          {
            Title: "KATRA",
            Url: "katra",
            Items: [],
          },
          {
            Title: "GULMARG",
            Url: "gulmarg",
            Items: [],
          },
          {
            Title: "SONMARG",
            Url: "sonmarg",
            Items: [],
          },
          {
            Title: "VAISHNO DEVI",
            Url: "vaishno-devi",
            Items: [],
          },
        ],
      },
      {
        Title: "KERALA",
        Url: "kerala",
        Items: [
          {
            Title: "COCHIN",
            Url: "cochin",
            Items: [],
          },
          {
            Title: "MUNNAR",
            Url: "munnar",
            Items: [],
          },
          {
            Title: "THEKKADY",
            Url: "thekkady",
            Items: [],
          },
          {
            Title: "KOTTAYAM",
            Url: "kottayam",
            Items: [],
          },
          {
            Title: "TRIVANDRUM",
            Url: "trivandrum",
            Items: [],
          },
          {
            Title: "KANYA KUMARI",
            Url: "kanya-kumari",
            Items: [],
          },
        ],
      },
      {
        Title: "LEH - LADAKH",
        Url: "leh-ladakh",
        Items: [
          {
            Title: "LEH",
            Url: "leh",
            Items: [],
          },
          {
            Title: "NUBRA VALLEY",
            Url: "nubra-valley",
            Items: [],
          },
          {
            Title: "PANGONG",
            Url: "pangong",
            Items: [],
          },
          {
            Title: "KARGIL",
            Url: "kargil",
            Items: [],
          },
        ],
      },
      // {
      //   Title: "MADHYA PRADESH",
      //   Url: "madhya-pradesh",
      //   Items: [
      //     {
      //       Title: "AMARKANTAK",
      //       Url: "amarkantak",
      //       Items: [],
      //     },
      //     {
      //       Title: "OMKARESHWAR",
      //       Url: "omkareshwar-",
      //       Items: [],
      //     },
      //     {
      //       Title: "PACHMARHI",
      //       Url: "pachmarhi",
      //       Items: [],
      //     },
      //     {
      //       Title: "UJJAIN",
      //       Url: "ujjain",
      //       Items: [],
      //     },
      //   ],
      // },
      // {
      //   Title: "MAHABALESHWAR",
      //   Url: "mahabaleshwar",
      //   Items: [
      //     {
      //       Title: "MAHABALESHWAR",
      //       Url: "mahabaleshwar-",
      //       Items: [],
      //     },
      //     {
      //       Title: "LONAVALA",
      //       Url: "lonavala",
      //       Items: [],
      //     },
      //   ],
      // },
      {
        Title: "SIKKIM DARJEELING",
        Url: "sikkim-darjeeling",
        Items: [
          {
            Title: "GANGTOK",
            Url: "gangtok",
            Items: [],
          },
          {
            Title: "DARJEELING",
            Url: "darjeeling",
            Items: [],
          },
          {
            Title: "LACHUNG",
            Url: "lachung",
            Items: [],
          },
          {
            Title: "PELLING",
            Url: "pelling",
            Items: [],
          },
        ],
      },
      // {
      //   Title: "SOUTH INDIA",
      //   Url: "south-india",
      //   Items: [
      //     {
      //       Title: "COORG",
      //       Url: "coorg",
      //       Items: [],
      //     },
      //     {
      //       Title: "MYSORE",
      //       Url: "mysore",
      //       Items: [],
      //     },
      //     {
      //       Title: "OOTY",
      //       Url: "ooty",
      //       Items: [],
      //     },
      //     {
      //       Title: "BANGALORE",
      //       Url: "bangalore",
      //       Items: [],
      //     },
      //   ],
      // },
      {
        Title: "UTTARAKHAND",
        Url: "uttarakhand",
        Items: [
          {
            Title: "MUSSOORIE",
            Url: "mussoorie",
            Items: [],
          },
          {
            Title: "CORBETT",
            Url: "corbett-",
            Items: [],
          },
          {
            Title: "KAUSANI",
            Url: "kausani",
            Items: [],
          },
          {
            Title: "BHIMTAL",
            Url: "bhimtal",
            Items: [],
          },
          {
            Title: "NAINITAL",
            Url: "nainital",
            Items: [],
          },
          {
            Title: "RISHIKESH",
            Url: "rishikesh-",
            Items: [],
          },
          {
            Title: "HARIDWAR",
            Url: "haridwar",
            Items: [],
          },
        ],
      },
      {
        Title: "VAISHNODEVI PATNITOP SHIVKHODI",
        Url: "vaishnodevi-patnitop-shivkhodi",
        Items: [
          {
            Title: "KATRA",
            Url: "katra",
            Items: [],
          },
          {
            Title: "AMRITSAR",
            Url: "amritsar",
            Items: [],
          },
        ],
      },
      {
        Title: "JAGGANATH PURI",
        Url: "jagganath-puri",
        Items: [],
      },
      {
        Title: "UTTAR PRADESH",
        Url: "uttar-pradesh",
        Items: [],
      },
      {
        Title: "SOUTH INDIA",
        Url: "south",
        Items: [],
      },
    ],
  },
  {
    Title: "International",
    Url: "https://international.ajaymodi.com/",
    Items: [
      // {
      //   Title: "AMERICA",
      //   Url: "america",
      //   Items: [],
      // },
      // {
      //   Title: "DUBAI",
      //   Url: "dubai",
      //   Items: [],
      // },
      // {
      //   Title: "EUROPE",
      //   Url: "europe",
      //   Items: [],
      // },
      // {
      //   Title: "INDONESIA",
      //   Url: "indonesia",
      //   Items: [
      //     {
      //       Title: "BALI",
      //       Url: "bali",
      //       Items: [],
      //     },
      //   ],
      // },
      // {
      //   Title: "SINGAPORE MALAYSIA THAILAND",
      //   Url: "singapore-malaysia-thailand",
      //   Items: [],
      // },
      // {
      //   Title: "SOUTH AFRICA",
      //   Url: "south-africa",
      //   Items: [],
      // },
    ],
  },
  // {
  //   Title: "New Packages",
  //   Url: "https://international.ajaymodi.com/",
  //   Items: [],
  // },
  {
    Title: "Sasta Air Ticket",
    Url: "https://ajaymodi.co/",
    Items: [],
  },
  {
    Title: "Couple Tours",
    Url: "couple-tour",
    Items: [],
  },
  {
    Title: "Customized Holidays",
    Url: "customized-holidays",
    Items: [],
  },
  {
    Title: "Weekend Gateways",
    Url: "",
    Items: [
      {
        Title: "GOA",
        Url: "goa",
        Items: [],
      },
      {
        Title: "UDAIPUR",
        Url: "udaipur",
        Items: [],
      },
      {
        Title: "KUMBHALGARH",
        Url: "kumbhalgarh",
        Items: [],
      },
    ],
  },
  {
    Title: "About Us",
    Url: "about-us",
    Items: [],
  },
  {
    Title: "Blogs",
    Url: "blogs",
    Items: [],
  },
  {
    Title: "Contact",
    Url: "contact-us",
    Items: [],
  },
];
