import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getblogDatail } from "./blogDetailSlice";
import BlogDetailSection from "./BlogDetailSection";
import PageTitle from "../../components/PageTitle";
import { useLocation, useParams } from "react-router-dom";

const BlogDetailPage = () => {
  const dispatch = useDispatch();
  const { blogUrl } = useParams();
  const { BlogDetail, BlogDetailBanner } = useSelector(
    (state) => state.blogDetail.BlogDetailsData
  );
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  useEffect(() => {
    const payload = {
      BlogURL: blogUrl,
    };
    dispatch(getblogDatail(payload));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{BlogDetail?.MetaTitle}</title>
        <meta name="description" content={BlogDetail?.MetaDescription} />
        <meta name="keywords" content={BlogDetail?.MetaKeywords} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <PageTitle BannerTitle={"Blog Detail"} BannerImage={BlogDetailBanner} />
      <BlogDetailSection />
    </>
  );
};

export default BlogDetailPage;
