import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getCustomizeHolidays = createAsyncThunk(
  "CustomizedHoliday/CustomizedHolidayPageData",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_APP_URL}/CustomizedHoliday/CustomizedHolidayPageData`
    );
    const CHolidaysData = response.data.Data || {};
    const SeoPoints = {
      MetaTitle: response.data?.MetaTitle || "",
      MetaDescription: response.data?.MetaDescription || "",
      Canonical: response.data?.Canonical || "",
      MetaKeywords: response.data?.MetaKeywords || "",
    };
    const CHolidaysBanner = response.data?.BannerImage || "";
    return { CHolidaysData, SeoPoints, CHolidaysBanner };
  }
);

const initialState = {
  loading: false,
  error: "",
  CustomizeHolidaysData: {
    CHolidaysData: {},
    SeoPoints: {},
    CHolidaysBanner: "",
  },
};

const customizeHolidaySlice = createSlice({
  name: "customizeHolidaySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomizeHolidays.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCustomizeHolidays.fulfilled, (state, action) => {
      state.loading = false;
      state.CustomizeHolidaysData = action.payload;
      state.error = "";
    });
    builder.addCase(getCustomizeHolidays.rejected, (state, action) => {
      state.loading = false;
      state.CustomizeHolidaysData = {};
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default customizeHolidaySlice.reducer;
