import React from "react";
import { openDialog } from "../../pages/home/homeSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const WhatsappandInquiryBtn = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname.split("/")[1];

  return (
    <ul class="whatsapp-inquiry-btns">
      <li className="inquiry-button" onClick={() => dispatch(openDialog())}>
        <a href="javascript:void(0)">
          <i class="fa fa-file-text-o"></i>
          <span>Send Inquiry</span>
        </a>
      </li>
      <li className="whatsapp-button">
        <a
          href={
            pathname === "travel-agents"
              ? "https://wa.me/918412963336/"
              : "https://wa.me/917567096999/"
          }
          target="_blank"
        >
          <i class="fa fa-whatsapp"></i>
          <span>Whatsapp</span>
        </a>
      </li>
    </ul>
  );
};

export default WhatsappandInquiryBtn;
