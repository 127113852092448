import React from "react";
import { SHORT_BY_FILTER } from "../../common/constantArray";
import Select from "react-select";
import { useSelector } from "react-redux";

const PackageListToolBar = ({ formik }) => {
  const TourListData = useSelector((state) => state.packageList.TourListData);

  const handleSortChange = (selectedOption) => {
    formik.setFieldValue("OrderBy", selectedOption);
  };

  return (
    <>
      {TourListData?.TourList && (
        <div className="toolbar">
          <div className="showing-tour">
            Showing{" "}
            {(TourListData?.TourList &&
              TourListData?.TourList[0]?.TotalCountData) ||
              0}{" "}
            Tour Packages
          </div>
          <Select
            options={SHORT_BY_FILTER}
            onChange={handleSortChange}
            value={
              formik.values.OrderBy
                ? SHORT_BY_FILTER.find(
                    (item) => item.value === formik.values.OrderBy
                  )
                : null
            }
          />
        </div>
      )}
    </>
  );
};

export default PackageListToolBar;
