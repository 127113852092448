import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getFooterDetail = createAsyncThunk(
  "HomePage/GetCMSPageDetails",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_APP_URL}/HomePage/GetCMSPageDetails?pageURL=footer`
    );
    const FooterData = response.data?.Data?.Template || [];
    return FooterData;
  }
);

const initialState = {
  loading: false,
  error: "",
  FooterData: {
    FooterData: "",
  },
};

const footerSlice = createSlice({
  name: "footerSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFooterDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFooterDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.FooterData = action.payload;
      state.error = "";
    });
    builder.addCase(getFooterDetail.rejected, (state, action) => {
      state.loading = false;
      state.FooterData = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default footerSlice.reducer;
