import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getAllNews = createAsyncThunk(
  "News/NewsFindAllActive",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_APP_URL}/News/NewsFindAllActive`
    );
    const NewsListData = response.data;
    return NewsListData;
  }
);


const initialState = {
  loading: false,
  error: "",
  NewsListData: [],
};

const newsListSlice = createSlice({
  name: "newsListSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllNews.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllNews.fulfilled, (state, action) => {
      state.loading = false;
      state.NewsListData = action.payload;
      state.error = "";
    });
    builder.addCase(getAllNews.rejected, (state, action) => {
      state.loading = false;
      state.NewsListData = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default newsListSlice.reducer;
