import React from "react";
import { useSelector } from "react-redux";
import NEWS_DETAILS1 from "../../assets/images/blog-detail-1.jpg";
import NEWS_DETAILS2 from "../../assets/images/blog-dertail.jpg";
import USER_ICON from "../../assets/images/detail-user.png";
import CLOCK_ICON from "../../assets/images/detail-clock.png";

const NewsDetailSection = () => {
  const { NewsDetails } = useSelector(
    (state) => state.newsDetail.NewsDetailsData
  );

  return (
    <>
      <div className="blog-detail">
        <div className="container">
          <h2>{NewsDetails?.NewsTitle}</h2>
          <p className="blog-post-time">
            {/* <span>
              <img src={USER_ICON} alt="User" />
              {NewsDetails?.NewsDate}
            </span> */}
            <span>
              <img src={CLOCK_ICON} alt="Clock" />
              {NewsDetails?.NewsDate}
            </span>{" "}
          </p>
          <div className="blog-detail-img">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <img src={NewsDetails?.NewsImage} alt="News Detail" />
              </div>
              {/* <div className="col-md-6 col-sm-12">
                <img src={NEWS_DETAILS2} alt="" />
              </div> */}
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: NewsDetails?.LongDescription }}
          />
        </div>
      </div>
    </>
  );
};

export default NewsDetailSection;
