import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDomesticDest } from "./domesticDestSlice";
import { useLocation } from "react-router-dom";
import PAGE_TITLE_BG from "../../assets/images/home-banner.jpg";
import PageTitle from "../../components/PageTitle";
import { Helmet } from "react-helmet";
import DomesticDestList from "./DomesticDestList";

const DomesticDestPage = () => {
  const dispatch = useDispatch();
  const { DomesticDestListSeo } = useSelector((state) => state.domesticDest);

  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  useEffect(() => {
    dispatch(getAllDomesticDest());
  }, []);

  return (
    <>
      <Helmet>
        <title>{DomesticDestListSeo?.MetaTitle}</title>
        <meta
          name="description"
          content={DomesticDestListSeo?.MetaDescription}
        />
        <meta name="keywords" content={DomesticDestListSeo?.MetaKeywords} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <PageTitle
        BannerTitle={"Domestic Destinations"}
        BannerImage={PAGE_TITLE_BG}
      />
      <DomesticDestList />
    </>
  );
};

export default DomesticDestPage;
