import React from "react";
import USER_ICON from "../../assets/images/detail-user.png";
import CLOCK_ICON from "../../assets/images/detail-clock.png";
import VOUCTURE_ICON from "../../assets/images/detail-voucture.png";
import { useNavigate } from "react-router";

const NewsListCard = ({ news }) => {
  const navigate = useNavigate();

  const handlePackageNavigate = (news) => {
    if (news?.NewsURL) {
      navigate(`/news/${news?.NewsURL}/`);
    }
  };

  return (
    <div className="blog-post-column">
      <div className="blog-post-pic">
        <img src={news?.NewsImage} alt="" />
      </div>
      <div className="blog-post-con">
        <p className="blog-post-time">
          {/* <span>
            <img src={USER_ICON} alt="User" />
            {news?.NewsDate}
          </span> */}
          <span>
            <img src={CLOCK_ICON} alt="Clock" />
            {news?.NewsDate}
          </span>{" "}
          {/* <span>
            <img src={VOUCTURE_ICON} alt="Voucture" />
            {news?.NewsDate}
          </span> */}
        </p>
        <h2>
          <a> {news?.NewsTitle}</a>
        </h2>
        <p>{news?.ShortDescription}</p>
        <a
          className="btn btn-fill round"
          onClick={() => handlePackageNavigate(news)}
        >
          Read More
        </a>
      </div>
    </div>
  );
};

export default NewsListCard;
