import MainRoute from "./routes/MainRoute";
import "aos/dist/aos.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "jquery.flipster/dist/jquery.flipster.min.css";
import "jqueryui/jquery-ui.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "jquery.flipster/dist/jquery.flipster.min.js";
import "highcharts/highmaps";
import "highcharts/modules/exporting";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "jquery.hislide/jquery.hislide";
// import "jquery-ui/ui/widgets/slider";
// import "./price_range_script.js";
import "./App.css";

function App() {
  return (
    <div>
      <MainRoute />
    </div>
  );
}

export default App;
