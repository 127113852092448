import React from "react";
import { useSelector } from "react-redux";
import { Element } from "react-scroll";

const TourInformation = () => {
  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);

  return (
    <Element
      className="tab-pane fade show active"
      id="Tour"
      name="Tour-Information-tab"
      role="tabpanel"
      aria-labelledby="Tour-tab"
      style={{
        textAlign: "justify",
        padding: 15,
        boxShadow: "0 0 10px lightgray",
        borderRadius: 20,
        marginBottom: 30,
      }}
    >
      <h6
        style={{
          background: "antiquewhite",
          padding: 10,
          borderRadius: 10,
          fontWeight: 700,
          marginBottom: 10,
        }}
      >
        Tour Info
      </h6>
      <div
        dangerouslySetInnerHTML={{ __html: TourDetails?.TourInformation || "" }}
      ></div>
    </Element>
    // <div
    //   className="tab-pane fade show active"
    //   id="Tour"
    //   role="tabpanel"
    //   aria-labelledby="Tour-tab"
    //   dangerouslySetInnerHTML={{ __html: TourDetails?.TourInformation || "" }}
    // />
  );
};

export default TourInformation;
