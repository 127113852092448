import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getAboutUsDetail = createAsyncThunk(
  "/HomePage/AboutUS",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_APP_URL}/HomePage/GetCMSPageDetails?pageURL=about-us`
    );
    const AboutUsData = response.data?.Data || [];
    return AboutUsData;
  }
);

const initialState = {
  loading: false,
  error: "",
  AboutUsData: {
    AboutUsData: "",
  },
};

const aboutSlice = createSlice({
  name: "aboutSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAboutUsDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAboutUsDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.AboutUsData = action.payload;
      state.error = "";
    });
    builder.addCase(getAboutUsDetail.rejected, (state, action) => {
      state.loading = false;
      state.AboutUsData = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default aboutSlice.reducer;
