import WeekendGatewayDetailPage from "../pages/WeekendGatewayDetail/WeekendGatewayDetailPage";
import WeekendGatewaysPage from "../pages/WeekendGatways/WeekendGatewaysPage";
import AboutUsPage from "../pages/aboutus/AboutUsPage";
import Pune from "../pages/addresspages/Pune";
import BlogDetailPage from "../pages/blogDetail/BlogDetailPage";
import BlogListPage from "../pages/blogList/BlogListPage";
import ContactUsPage from "../pages/contactus/ContactUsPage";
import CHolidaysListPage from "../pages/customizeHolidaysList/CHolidaysListPage";
import DomesticDestPage from "../pages/domesticDest/DomesticDestPage";
import NewsDetailPage from "../pages/newsDetail/NewsDetailPage";
import NewsListPage from "../pages/newsList/NewsListPage";
import PackageDetailPage from "../pages/packageDetail/PackageDetailPage";
import PackageListPage from "../pages/packageList/PackageListPage";
import ThankYouPage from "../pages/thankyou/ThankYouPage";

export const publicRoutes = [
  { path: "/blogs/", component: <BlogListPage /> },
  { path: "/thankyou/", component: <ThankYouPage /> },
  { path: "/blogs/:blogUrl", component: <BlogDetailPage /> },
  { path: "/news/", component: <NewsListPage /> },
  { path: "/news/:newsUrl", component: <NewsDetailPage /> },
  { path: "/contact-us/", component: <ContactUsPage /> },
  { path: "/about-us/", component: <AboutUsPage /> },
  { path: "/customized-holidays/", component: <CHolidaysListPage /> },
  { path: "/domestic-destinations/", component: <DomesticDestPage /> },
  { path: "/package/:TourURL/", component: <PackageDetailPage /> },
  { path: "/weekend-gateways/:WGUrl", component: <WeekendGatewaysPage /> },
  {
    path: "/weekend-gateways/:WGUrl/:SubWGUrl",
    component: <WeekendGatewayDetailPage />,
  },
  { path: "/:MainRoute/:SectorURL/", component: <PackageListPage /> },
  {
    path: "/:MainRoute/:SectorURL/:Customize/",
    component: <PackageListPage />,
  },
  {
    path: "/travel-agents/pune",
    component: <Pune />,
  },
  { path: "/pune-thankyou/", component: <ThankYouPage /> },
];
