import { configureStore } from "@reduxjs/toolkit";
import packageListSlice from "../pages/packageList/packageListSlice";
import packageDetailSlice from "../pages/packageDetail/packageDetailSlice";
import homeSlice from "../pages/home/homeSlice";
import customizeHolidaySlice from "../pages/customizeHolidaysList/customizeHolidaySlice";
import blogListSlice from "../pages/blogList/blogListSlice";
import blogDetailSlice from "../pages/blogDetail/blogDetailSlice";
import newsDetailSlice from "../pages/newsDetail/newsDetailSlice";
import newsListSlice from "../pages/newsList/newsListSlice";
import weekenGatewaySlice from "../pages/WeekendGatways/weekenGatewaySlice";
import footerSlice from "../layout/Footer/footerSlice";
import contactSlice from "../pages/contactus/contactSlice";
import aboutSlice from "../pages/aboutus/aboutSlice";
import weekendGateWayDetailSlice from "../pages/WeekendGatewayDetail/weekendGateWayDetailSlice";
import domesticDestSlice from "../pages/domesticDest/domesticDestSlice";

const store = configureStore({
  reducer: {
    home: homeSlice,
    packageList: packageListSlice,
    packageDetail: packageDetailSlice,
    customizeHolidays: customizeHolidaySlice,
    blogList: blogListSlice,
    blogDetail: blogDetailSlice,
    newsDetail: newsDetailSlice,
    newsList: newsListSlice,
    weekendGateway: weekenGatewaySlice,
    footer: footerSlice,
    contact: contactSlice,
    about: aboutSlice,
    weekendGatewayDetail: weekendGateWayDetailSlice,
    domesticDest: domesticDestSlice,
  },
});

export default store;
