import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getAllBlogs = createAsyncThunk(
  "Blog/BlogFindAllActive",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_APP_URL}/Blog/BlogFindAllActive`
    );
    const BlogListData = response.data;
    return BlogListData;
  }
);

const initialState = {
  loading: false,
  error: "",
  BlogListData: {},
};

const blogListSlice = createSlice({
  name: "blogListSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllBlogs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllBlogs.fulfilled, (state, action) => {
      state.loading = false;
      state.BlogListData = action.payload;
      state.error = "";
    });
    builder.addCase(getAllBlogs.rejected, (state, action) => {
      state.loading = false;
      state.BlogListData = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default blogListSlice.reducer;
