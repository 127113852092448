import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BACK_IMAGE from "../../assets/images/blog-bg.jpg";

const BlogSection = () => {
  const { BlogList } = useSelector((state) => state.home.HomeData);
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    if (url) {
      navigate(`/blogs/${url}/`);
    }
  };

  return (
    <>
      {BlogList && (
        <div className="blog-section full-section relative">
          <div className="bg-sections">
            <img src={BACK_IMAGE} />
          </div>
          <div className="container relative">
            <h2 className="text-center">Happening Now in #world</h2>
            <div className="row">
              {BlogList?.map((blog) => (
                <div className="col-sm-12 col-md-4">
                  <div className="blog-box">
                    <div className="blog-pic">
                      <img src={blog?.BlogImage} />
                    </div>
                    <div className="blog-contnt">
                      <span className="timedate">{blog?.BlogDate}</span>
                      <h5>{blog?.BlogTitle}</h5>
                      <div className="showhove-blog">
                        <p>
                          {blog?.ShortDescription}
                          {/* {lengthTruncator(
                            blog?.LongDescription,
                            LONG_DESCRIPTION_LENGTH
                          )} */}
                        </p>
                        <a
                          className="anchorbtn"
                          onClick={() => handleNavigate(blog?.BlogURL)}
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogSection;
