import React from "react";
import { Link, useLocation } from "react-router-dom";

const BredCrum = () => {
  const { pathname } = useLocation();
  const pathSegments = pathname.split("/").filter((segment) => segment !== "");
  return (
    <div className="bredcrum-gray">
      <div className="container container-1480">
        <div className="breadrm">
          <Link to="/">Home</Link> {">"}
          {pathSegments && (
            <span>
              {pathSegments[pathSegments.length - 1]
                ?.split("-")
                ?.join(" ")
                ?.toUpperCase()}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default BredCrum;
