import React, { useEffect, useState } from "react";
import { NO_OF_DAYS } from "../../common/constantArray";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTourListFilter,
  getAllSpecialityTypes,
  getAllTourTypes,
  setTourListFilter,
} from "./packageListSlice";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const PackageListFilter = ({ formik }) => {
  const dispatch = useDispatch();
  const TourTypes = useSelector((state) => state.packageList.TourTypes);
  const SpecialityTypes = useSelector(
    (state) => state.packageList.SpecialityTypes
  );
  const TourListFilter = useSelector(
    (state) => state.packageList.TourListFilter
  );

  const TourListData = useSelector((state) => state.packageList.TourListData);

  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [minRate, setMinRate] = useState(
    formik.values?.Rate ? formik.values.Rate[0] : 0
  );
  const [maxRate, setMaxRate] = useState(
    formik.values?.Rate ? formik.values.Rate[1] : 200000
  );

  useEffect(() => {
    dispatch(getAllTourTypes());
    dispatch(getAllSpecialityTypes());

    return () => {
      dispatch(clearTourListFilter());
    };
  }, []);

  const handlePriceRangeChange = (e) => {
    formik.setFieldValue("Rate", e);
    setMinRate(e[0]);
    setMaxRate(e[1]);
    dispatch(
      setTourListFilter({
        ...TourListFilter,
        Rate: e,
      })
    );
  };

  const handleMinRateChange = (e, minValue, maxValue) => {
    let inputValue = parseInt(e.target.value, 10);
    if (isNaN(inputValue) || inputValue < minValue) {
      e.preventDefault();
      inputValue = minValue;
    } else if (inputValue > maxValue) {
      e.preventDefault();
      inputValue = maxValue;
    }
    setMinRate(inputValue);
    formik.setFieldValue("Rate", [inputValue, maxRate]);
  };

  const handleMaxRateChange = (e, minValue, maxValue) => {
    let inputValue = parseInt(e.target.value, 10);
    if (isNaN(inputValue) || inputValue < minValue) {
      e.preventDefault();
      inputValue = minValue;
    } else if (inputValue > maxValue) {
      e.preventDefault();
      inputValue = maxValue;
    }
    setMaxRate(inputValue);
    formik.setFieldValue("Rate", [minRate, inputValue]);
  };

  const handleCheckboxChange = (value, arrayField) => {
    formik.setFieldValue(
      arrayField,
      formik.values[arrayField]?.includes(value)
        ? formik.values[arrayField]?.filter((item) => item !== value)
        : [...formik.values[arrayField], value]
    );
  };

  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 side-filters-col">
        <div className="mobile-toggle-filters">
          <div
            className="shortby shortby-fltr"
            onClick={() => {
              setMobileFilterOpen(true);
            }}
          >
            Sort &amp; filter
          </div>
        </div>
        <div
          className={`side-filters ${
            mobileFilterOpen ? "filters-active" : ""
          } `}
        >
          <div
            className="mobile-filter-close"
            onClick={() => {
              setMobileFilterOpen(false);
            }}
          >
            Sort &amp; Filter <span>✕</span>
          </div>

          <div className="filter-title">
            <h4>Filters</h4>
          </div>
          <div className="filter-items-main">
            <div className="filter-items">
              <h4>Trip Length</h4>
              <ul className="filter-items-ul">
                {NO_OF_DAYS?.map((item) => (
                  <li>
                    <label className="filter-checkbox-label">
                      <input
                        type="checkbox"
                        className="filter-checkbox"
                        name="NoOfDays"
                        value={item.value}
                        checked={formik.values?.NoOfDays?.includes(item.value)}
                        onChange={() =>
                          handleCheckboxChange(item.value, "NoOfDays")
                        }
                      />
                      {item.label}
                    </label>
                  </li>
                ))}
              </ul>
            </div>

            <div className="filter-items">
              <h4>Price</h4>
              <div>
                <RangeSlider
                  min={0}
                  max={200000}
                  step={2}
                  defaultValue={formik.values?.Rate}
                  value={formik.values?.Rate}
                  onInput={handlePriceRangeChange}
                  id="range-slider-yellow"
                />
              </div>
              <div className="under-range-bar-price">
                <div className="min-prce">
                  <input
                    type="number"
                    min={0}
                    max={199000}
                    name="minRate"
                    id="min_price"
                    className="price-range-field"
                    value={minRate}
                    onChange={(e) => handleMinRateChange(e, 0, 199000)}
                  />
                </div>
                <div className="max-prce">
                  <input
                    type="number"
                    min={1000}
                    max={200000}
                    name="maxRate"
                    id="max_price"
                    className="price-range-field"
                    value={maxRate}
                    onChange={(e) => handleMaxRateChange(e, 1000, 200000)}
                  />
                </div>
              </div>
            </div>

            <div className="filter-items">
              <h4>Package type</h4>
              <ul className="filter-items-ul">
                {TourTypes?.map((item) => (
                  <li>
                    <label className="filter-checkbox-label">
                      <input
                        type="checkbox"
                        className="filter-checkbox"
                        name="TourType"
                        value={item.ID}
                        checked={formik.values?.TourType?.includes(item.ID)}
                        onChange={() =>
                          handleCheckboxChange(item.ID, "TourType")
                        }
                      />
                      {item.Title}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="filter-items">
              <h4>Speciality Tour Packages</h4>
              <ul className="filter-items-ul">
                {SpecialityTypes?.map((item) => (
                  <li>
                    <label className="filter-checkbox-label">
                      <input
                        type="checkbox"
                        className="filter-checkbox"
                        name="SpecialityType"
                        value={item.ID}
                        checked={formik.values?.SpecialityType?.includes(
                          item.ID
                        )}
                        onChange={() =>
                          handleCheckboxChange(item.ID, "SpecialityType")
                        }
                      />
                      {item.Title}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageListFilter;
