import React from "react";
import { useSelector } from "react-redux";

const MainHeader = () => {
  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);

  return (
    <div className="tour-detail-top">
      <div className="tour-detail-title">
        <h3>{TourDetails?.TourName}</h3>
        <div className="detiltitle-under">
          <div className="days-desgin">
            <span>{TourDetails?.NoOfDays} Days</span>{" "}
            <strong>{TourDetails?.NoOfNights} Nights</strong>
          </div>
          {/* <div className="share-wishlist">
            <a href="#">
              <img src="./images/share-ico.png" />
            </a>{" "}
            |
            <a href="#">
              <img src="./images/whislist.png" />
            </a>
          </div> */}
        </div>
      </div>
      <div className="tour-detail-price">
        <span className="pricestaring">
          {TourDetails?.tourcost &&
            TourDetails?.tourcost[0] &&
            TourDetails?.tourcost[0]?.RoomType}{" "}
          <br />
          <strong>
            {TourDetails?.tourcost &&
              TourDetails?.tourcost[0] &&
              "₹" + TourDetails?.tourcost[0]?.Rate}
          </strong>
        </span>
        <p>
          {TourDetails?.tourcost &&
            TourDetails?.tourcost[0] &&
            TourDetails?.tourcost[0]?.RateType}
        </p>
      </div>
    </div>
  );
};

export default MainHeader;
