import React from "react";
import { Link } from "react-router-dom";
import PAGE_NOT_FOUND from "../../assets/images/pagenotfound.png";

const PageNotFound = () => {
  return (
    <div
      className="page-not-found"
      style={{
        backgroundImage: `url(${PAGE_NOT_FOUND})`,
      }}
    >
      <div className="item">
        <div className="container">
          <div className="page-not-found-text-boxes">
            <div className="page-not-found-box">
              <div className="container">
                <div className="page-not-found-leftside">
                  <h3>YOU ARE GOING NO WHERE</h3>
                  <h5>IT'S JUST 404 ERROR PAGE</h5>
                  <p>
                    And Obviously, You don't have to worry, as we are always
                    here to guide you
                  </p>
                  <Link className="back-to-home-btn" to="/">
                    Back to home
                  </Link>
                </div>
              </div>
            </div>
            <div className="banner-pic"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
