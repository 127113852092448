import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  INDIAN_PACKAGE_PATH_KEY,
  INTERNATIONAL_PACKAGE_PATH_KEY,
} from "../../common/costants";

const PersionlizedPackages = () => {
  const { CHolidaysData } = useSelector(
    (state) => state.customizeHolidays.CustomizeHolidaysData
  );

  const navigate = useNavigate();

  const handleNavigate = (dest) => {
    if (dest?.DestinationURL) {
      navigate(
        `/${
          dest?.SectorType === "INTERNATIONAL"
            ? INTERNATIONAL_PACKAGE_PATH_KEY
            : INDIAN_PACKAGE_PATH_KEY
        }/${dest?.DestinationURL}-tour-packages/`
      );
    }
  };

  return (
    <div className="Personalized-package-section">
      <div className="container">
        <div className="row">
          {CHolidaysData?.DestinationList?.map((dest) => (
            <div className="col-md-3 col-sm-6">
              <div className="Personalized-package">
                <div
                  className="discover-pic"
                  onClick={() => handleNavigate(dest)}
                >
                  <img src={dest?.DestinationImage} />
                </div>
                <h4>{dest?.SectorName}</h4>
                {/* <p>Jaipur</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersionlizedPackages;
