import React, { useState } from "react";
import TRAIL_BG_PIC from "../../assets/images/trail-bg.jpg";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  INDIAN_PACKAGE_PATH_KEY,
  INTERNATIONAL_PACKAGE_PATH_KEY,
} from "../../common/costants";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";

const PickupTrailIndia = () => {
  const HomeData = useSelector((setting) => setting.home.HomeData);

  const [setting, setSetting] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: false,
    enableSwipe: true,
    config: config.slow,
  });

  const handleNavigate = (dest) => {
    if (dest?.DestinationURL === "goa") {
      navigate("weekend-gateways/goa-tour-packages/");
    } else {
      navigate(
        `/${
          dest?.SectorType === "INTERNATIONAL"
            ? INTERNATIONAL_PACKAGE_PATH_KEY
            : INDIAN_PACKAGE_PATH_KEY
        }/${dest?.DestinationURL}-tour-packages/`
      );
    }
  };

  const [isTouched, setIsTouched] = useState(false);

  const handleTouchStart = () => {
    setIsTouched(true);
  };

  const handleTouchEnd = () => {
    setIsTouched(false);
  };

  const defaultSlides = (HomeData?.PickYourTrailList || []).map((trail) => ({
    key: uuidv4(),
    content: (
      <div
        key={trail.SectorName}
        className={`item ${isTouched ? "touched" : ""}`}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <div className="trail-slid-pic">
          {/* <div className="trail-slid-pic" onClick={() => handleNavigate(trail)}> */}
          <img src={trail.PickYourTrailImage} alt={trail.SectorName} />
          <div className="trail-slid-data">
            <h4
              onClick={() => handleNavigate(trail)}
              className="hoverable-title"
            >
              {trail.SectorName}
            </h4>
            <p
              onClick={() => handleNavigate(trail)}
              className="hoverable-title"
            >
              Explore
            </p>
          </div>
        </div>
      </div>
    ),
  }));

  const slides = defaultSlides.map((slide, index) => ({
    ...slide,
    onClick: () =>
      setSetting((prevSetting) => ({ ...prevSetting, goToSlide: index })),
  }));

  const navigate = useNavigate();

  return (
    <div className="trail-section full-section relative">
      <div className="bg-sections">
        <img src={TRAIL_BG_PIC} alt="Trail Background" />
      </div>
      <div className="container relative">
        <h2 className="text-center">
          Pick Your Trail - <span>See India</span> - As per your Interest
        </h2>
        <div
          className="flip-items-div"
          style={{ height: "450px", padding: "0 0 30px 0" }}
        >
          <Carousel
            slides={slides}
            goToSlide={setting.goToSlide}
            offsetRadius={setting.offsetRadius}
            showNavigation={setting.showNavigation}
            animationConfig={config.gentle}
          />
        </div>
      </div>
    </div>
  );
};

export default PickupTrailIndia;
