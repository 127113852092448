import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getTourDetails = createAsyncThunk(
  "Tour/TourDetails",
  async (payload) => {
    const response = await axiosApiInstance.post(
      `${API_APP_URL}/Tour/TourDetails`,
      payload
    );
    const TourDetails = response.data?.Data[0] || {};

    return TourDetails;
  }
);

const initialState = {
  loading: false,
  error: "",
  TourDetails: {},
};

const packageDetailSlice = createSlice({
  name: "packageDetailSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTourDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTourDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.TourDetails = action.payload;
      state.error = "";
    });
    builder.addCase(getTourDetails.rejected, (state, action) => {
      state.loading = false;
      state.TourDetails = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default packageDetailSlice.reducer;
