import axios from "axios";

export const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  function (config) {
    // config.headers.Authorization = `Bearer ${localStorage.getItem(
    //   "access_token"
    // )}`;
    return Promise.resolve(config);
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use((onFullfilled) => {
  if (onFullfilled.data.Status === 201) {
    return Promise.reject(onFullfilled.data.Details);
  }
  return Promise.resolve(onFullfilled);
});

