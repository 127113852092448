import React from "react";
import INQUIRY_BG from "../../assets/images/home-form-bg.jpg";
import MAIL_ICON from "../../assets/images/mail-white.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { sendInquiry, sendInquiryToNeoDoveSoftware } from "./homeSlice";
import { useNavigate } from "react-router-dom";

const InquirySection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inquiryLoading = useSelector((state) => state.home.inquiryLoading);

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Full Name is required"),
    EmailID: Yup.string()
      .email("Invalid email address")
      .required("Email Address is required"),
    MobileNo: Yup.number().required("Mobile Number is required"),
    Remarks: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      Name: "",
      EmailID: "",
      MobileNo: "",
      Remarks: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        ...values,
        MobileNo: values?.MobileNo?.toString(),
      };

      const neodovePayload = {
        name: values?.Name,
        mobile: values?.MobileNo,
        email: values?.EmailID,
        detail: values?.Remarks,
        detail2: "",
      };

      try {
        await dispatch(sendInquiry(payload)).unwrap();
        await dispatch(sendInquiryToNeoDoveSoftware(neodovePayload)).unwrap();
        navigate("/thankyou/");
        resetForm();
      } catch (error) {
        console.error("API error:", error);
      }
    },
  });

  return (
    <div className="tellabout-section full-section relative">
      <div className="bg-sections">
        <img src={INQUIRY_BG} />
      </div>
      <div className="container relative">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="dropmsg">
              <h2>
                Drop a message. <br />
                Tell me about your project.
              </h2>
              <p>let's create something togather</p>
              <div className="mailus-at">
                <span className="mailtic">
                  <img src={MAIL_ICON} />
                </span>
                <h5>Mail me at</h5>
                <a href="mailto:info@ajaymodi.com">info@ajaymodi.com</a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <form onSubmit={formik.handleSubmit}>
              <input
                type="text"
                placeholder="Your Full Name"
                name="Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Name}
                className={
                  formik.touched.Name && formik.errors.Name
                    ? "error-border"
                    : ""
                }
              />
              <input
                name="EmailID"
                type="text"
                placeholder="Email Address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.EmailID}
                className={
                  formik.touched.EmailID && formik.errors.EmailID
                    ? "error-border"
                    : ""
                }
              />
              <input
                type="number"
                name="MobileNo"
                placeholder="Mobile Number"
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.MobileNo}
                className={
                  formik.touched.MobileNo && formik.errors.MobileNo
                    ? "error-border"
                    : ""
                }
              />
              <textarea
                placeholder="Write your message here..."
                name="Remarks"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Remarks}
                className={
                  formik.touched.Remarks && formik.errors.Remarks
                    ? "error-border"
                    : ""
                }
              />
              <button
                disabled={inquiryLoading}
                className="btn btn-fill"
                type="submit"
              >
                {inquiryLoading ? "Submiting Details" : "Send Message"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquirySection;
