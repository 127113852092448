import React, { useEffect, useState } from "react";
import INQUIRY_BG from "../../assets/images/home-form-bg.jpg";
import MAIL_ICON from "../../assets/images/mail-white.png";
import LOCATION_ICON from "../../assets/images/location.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllDestinations,
  sendInquiryForPune,
  sendInquiryToNeoDoveSoftware,
} from "../home/homeSlice";

const InquirySectionForPune = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inquiryLoading = useSelector((state) => state.home.inquiryLoading);
  const DestinationList = useSelector((state) => state.home.DestinationList);
  const [DestList, setDestList] = useState(DestinationList || []);

  useEffect(() => {
    dispatch(getAllDestinations());
  }, []);

  const phoneRegExp = /^[0-9]{10,10}$/;
  const emailRegExp = /^\S+@\S+\.\S+$/;

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Full Name is required"),
    EmailID: Yup.string()
      // .email("Invalid email address")
      .required("Email Address is required")
      .matches(emailRegExp, "Email is not valid"),
    MobileNo: Yup.string()
      .required("Mobile Number is required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    SectorType: Yup.string().required("Sector Type is required"),
    SectorID: Yup.string().required("Sector is required"),
    Remarks: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      Name: "",
      EmailID: "",
      MobileNo: "",
      SectorType: "",
      SectorID: "",
      Remarks: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        ...values,
        MobileNo: values?.MobileNo?.toString(),
        SectorID: values?.SectorID ? +values.SectorID : 0,
      };

      const filteredSector = DestinationList?.filter(
        (item) => item?.ID == values?.SectorID
      );

      const neodovePayload = {
        name: values?.Name,
        mobile: values?.MobileNo,
        email: values?.EmailID,
        detail: `${values?.SectorType} - ${filteredSector[0]?.SectorName}`,
        detail2: values?.Remarks,
      };

      try {
        await dispatch(sendInquiryForPune(payload)).unwrap();
        await dispatch(sendInquiryToNeoDoveSoftware(neodovePayload)).unwrap();
        navigate("/pune-thankyou/");
        resetForm();
      } catch (error) {
        console.error("API error:", error);
      }
    },
  });

  const handleSectorTypeChange = (e) => {
    const selectedSectorType = e.target.value;
    formik.handleChange(e);

    const filteredDestList =
      selectedSectorType === "DOMESTIC"
        ? DestinationList?.filter((dest) => dest.SectorType === "DOMESTIC")
        : DestinationList?.filter((dest) => dest.SectorType !== "DOMESTIC") ||
          [];

    formik.setFieldValue("SectorID", "");
    setDestList(filteredDestList);
  };

  return (
    <div className="tellabout-section full-section relative">
      <div className="bg-sections">
        <img src={INQUIRY_BG} />
      </div>
      <div className="container relative">
        <div className="row align-items-start">
          <div className="col-sm-12 col-md-6">
            <div
              className="dropmsg"
              style={{ display: "flex", flexDirection: "column", gap: 10 }}
            >
              <h2 className="mb-2">Contact Details</h2>
              <div className="mailus-at">
                <span className="mailtic">
                  <i class="bi bi-geo-alt" style={{ fontSize: 22 }}></i>
                </span>
                <a href="https://maps.app.goo.gl/SEZMdzTDUaCG27c77">
                  Shop No. 72, Lighthouse - The Shopping Magnet, Bibwewadi
                  Kondhwa Road, Bibwewadi, Pune 411037
                </a>
              </div>
              <div className="mailus-at">
                <span className="mailtic">
                  <i class="bi bi-envelope" style={{ fontSize: 22 }}></i>
                </span>
                <a href="mailto:pune@ajaymodi.com">pune@ajaymodi.com</a>
              </div>
              <div className="mailus-at">
                <span className="mailtic">
                  <i class="bi bi-telephone" style={{ fontSize: 22 }}></i>
                </span>
                <a href="tel:+919227086539">
                  +91 9227086539 <br />
                </a>
                <a href="tel:+918412963336">+91 8412963336</a>
              </div>
              <div className="mailus-at">
                <span className="mailtic">
                  <i class="bi bi-hourglass-split" style={{ fontSize: 22 }}></i>
                </span>
                <a>Mon - Sat : 11 am - 8 pm</a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <h5 className="text-white">
              Don’t Miss Out – Register for the Next Trip
            </h5>
            <form onSubmit={formik.handleSubmit}>
              <input
                type="text"
                placeholder="Your Full Name"
                name="Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Name}
                className={
                  formik.touched.Name && formik.errors.Name
                    ? "error-border"
                    : ""
                }
              />
              <input
                name="EmailID"
                type="text"
                placeholder="Email Address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.EmailID}
                className={
                  formik.touched.EmailID && formik.errors.EmailID
                    ? "error-border"
                    : ""
                }
              />
              <input
                type="number"
                name="MobileNo"
                placeholder="Mobile Number"
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.MobileNo}
                className={
                  formik.touched.MobileNo && formik.errors.MobileNo
                    ? "error-border"
                    : ""
                }
              />
              <select
                name="SectorType"
                onChange={handleSectorTypeChange}
                onBlur={formik.handleBlur}
                value={formik.values.SectorType}
                className={
                  formik.touched.SectorType && formik.errors.SectorType
                    ? "error-border"
                    : ""
                }
              >
                <option value="">Select Type</option>
                <option value="DOMESTIC">Domestic</option>
                <option value="INTERNATIONAL">International</option>
              </select>
              <select
                name="SectorID"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.SectorID}
                disabled={!formik.values.SectorType}
                className={
                  formik.touched.SectorID && formik.errors.SectorID
                    ? "error-border"
                    : ""
                }
              >
                <option value="">Select Destination</option>
                {DestList?.map((option) => {
                  return (
                    <option key={option?.ID} value={option?.ID}>
                      {option?.SectorName}
                    </option>
                  );
                })}
              </select>
              <textarea
                placeholder="Write your message here..."
                name="Remarks"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Remarks}
                className={
                  formik.touched.Remarks && formik.errors.Remarks
                    ? "error-border"
                    : ""
                }
              />
              <button
                disabled={inquiryLoading}
                className="btn btn-fill"
                type="submit"
              >
                {inquiryLoading ? "Submiting Details" : "Send Message"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquirySectionForPune;
