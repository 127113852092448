import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getAllBlogs } from "./blogListSlice";
import BlogList from "./BlogList";
import BlogListRecentWork from "./BlogListRecentWork";
import PageTitle from "../../components/PageTitle";
import { useLocation } from "react-router-dom";

const BlogListPage = () => {
  const dispatch = useDispatch();
  const BlogListData = useSelector((state) => state.blogList.BlogListData);
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{BlogListData?.MetaTitle}</title>
        <meta name="description" content={BlogListData?.MetaDescription} />
        <meta name="keywords" content={BlogListData?.MetaKeywords} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <PageTitle
        BannerTitle={"Blog List"}
        BannerImage={BlogListData?.BannerImage}
      />
      <div className="blog-post">
        <div className="container">
          <div className="row">
            <BlogList />
            {/* <BlogListRecentWork /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogListPage;
