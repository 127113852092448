import React, { useState } from "react";
import BACK_IMAGE from "../../../assets/images/explore-india-bg.jpg";
import ExploreIndiaMap from "./ExploreIndiaMap";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../components/common/LoadingSpinner";
import {
  INDIAN_PACKAGE_PATH_KEY,
  INTERNATIONAL_PACKAGE_PATH_KEY,
} from "../../../common/costants";
import { useNavigate } from "react-router-dom";

const ExploreIndia = () => {
  const MAPS = ["Destination", "Zone"];
  // const MAPS = ["Destination", "Zone", "Experience"];
  const navigate = useNavigate();
  const CitiesData = useSelector((setting) => setting.home.CitiesData);
  const ZoneData = useSelector((setting) => setting.home.ZoneData);
  const loading = useSelector((setting) => setting.home.citiesLoading);

  const [activeMap, setActiveMap] = useState(MAPS[0]);

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const handleNavigate = (city) => {
    if (activeMap === "Zone" ? city?.DestinationURL : city?.CityURL) {
      navigate(
        `/${
          city?.SectorType === "INTERNATIONAL"
            ? INTERNATIONAL_PACKAGE_PATH_KEY
            : INDIAN_PACKAGE_PATH_KEY
        }/${
          activeMap === "Zone" ? city?.DestinationURL : city?.CityURL
        }-tour-packages/`,
        {
          state: { tourPackageType: activeMap === "Zone" ? "Dest" : "City" },
        }
      );
    }
  };

  const settings = {
    className: "explore-word-slick",
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: "0px",
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="exploreindia-section full-section relative">
      <div className="bg-sections">
        <img src={BACK_IMAGE} />
      </div>
      <div className="container relative">
        <h2 className="text-center">Explore India</h2>

        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {MAPS?.map((map) => (
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${map === activeMap ? "active" : ""} `}
                onClick={() => setActiveMap(map)}
              >
                {map}
              </button>
            </li>
          ))}
        </ul>

        <div className="row exlore-row">
          <div className="col-sm-12 col-md-6">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="District"
                role="tabpanel"
                aria-labelledby="District-tab"
              >
                <div
                  className="cityBox tab-content-map"
                  id="highcharts-name-delhi"
                >
                  <div className="city-box-inner">
                    <h4>
                      {activeMap === "Zone"
                        ? ZoneData?.ZoneName
                        : CitiesData?.SectorName}
                    </h4>
                    <p>
                      {activeMap === "Zone"
                        ? ZoneData?.ShortDescription
                        : CitiesData?.ShortDescription}
                    </p>
                  </div>
                  {activeMap === "Zone" ? (
                    <Slider {...settings}>
                      {ZoneData?.DestinationList?.map((item) => (
                        <div className="item">
                          <div className="exlore-wold-pic">
                            <img
                              src={item?.DestinationImage}
                              onClick={() => handleNavigate(item)}
                            />
                            <div className="trail-slid-data">
                              <h4>{item?.SectorName}</h4>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <Slider {...settings}>
                      {CitiesData?.CitiesList?.map((item) => (
                        <div className="item">
                          <div className="exlore-wold-pic">
                            <img
                              src={item?.CityImage}
                              onClick={() => handleNavigate(item)}
                            />
                            <div className="trail-slid-data">
                              <h4>{item?.CityName}</h4>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <ExploreIndiaMap activeMap={activeMap} />
            {/* <div id="container"></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreIndia;
