import React from "react";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import BACK_IMAGE from "../../assets/images/top-destina-bg.jpg";
import { useNavigate } from "react-router-dom";
import {
  INDIAN_PACKAGE_PATH_KEY,
  INTERNATIONAL_PACKAGE_PATH_KEY,
} from "../../common/costants";
import Slider from "react-slick";

const TopInternationalDestination = () => {
  const HomeData = useSelector((state) => state.home.HomeData);
  const navigate = useNavigate();

  const settings = {
    className: "discover-slick",
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: "0px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNavigate = (dest) => {
    if (dest?.DestinationURL) {
      if (dest.DestinationURL.startsWith("https://")) {
        window.open(dest.DestinationURL);
      } else {
        navigate(
          `/${
            dest?.SectorType === "INTERNATIONAL"
              ? INTERNATIONAL_PACKAGE_PATH_KEY
              : INDIAN_PACKAGE_PATH_KEY
          }/${dest?.DestinationURL}-tour-packages/`
        );
      }
    }
  };

  return (
    <div className="to-destina-section full-section relative">
      <div className="bg-sections">
        <img src={BACK_IMAGE} />
      </div>
      <div className="container relative">
        <h2 className="text-center">Top International Destinations</h2>
        <Slider {...settings}>
          {/* <div className="row"> */}
          {/* <OwlCarousel
          className="owl-carousel destination-owl owl-theme"
          loop
          margin={25}
          dots={false}
          nav
          autoplay={true}
          autoplayTimeout={4000}
          autoplayHoverPause={true}
          responsive={{
            0: {
              items: 1,
            },
            600: {
              items: 2,
            },
            1000: {
              items: 3,
            },
          }}
        > */}
          {HomeData?.InternationalDestinationList?.map((dest) => (
            <div className="item">
              {/* <div className="item col-md-3 col-sm-6"> */}
              <div className="destination-box-slid">
                <div className="destination-pic">
                  <img src={dest?.InternationalImage} />
                </div>
                <div className="destination-data">
                  <h4 onClick={() => handleNavigate(dest)}>
                    {dest?.SectorName}
                  </h4>
                  {/* <small>
                    {" "}
                    {lengthTruncator(
                      dest?.LongDescription,
                      LONG_DESCRIPTION_LENGTH
                    )}
                  </small> */}
                  <small>{dest?.ShortDescription}</small>
                  <button
                    onClick={() => handleNavigate(dest)}
                    className="btn btn-fill round"
                  >
                    Explore
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {/* </div> */}
        {/* </OwlCarousel> */}
      </div>
    </div>
  );
};

export default TopInternationalDestination;
