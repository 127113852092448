import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const RecViewedPackages = () => {
  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    if (url) {
      navigate(`/package/${url}`);
    }
  };

  return (
    <div className="packages-sidebar-box">
      <div className="packages-sidebar-box-title">
        <h5>Recent Viewed Packages</h5>
      </div>
      <div className="packages-sidebar-box-data">
        {TourDetails?.recentTours?.map((tour) => (
          <div className="recent-items">
            <div className="recentpic">
              <img
                src={tour?.TourImage}
                className="cursor-pointer"
                onClick={() => {
                  handleNavigate(tour?.TourURL);
                }}
              />
            </div>
            <h6
              className="hoverable-title"
              onClick={() => {
                handleNavigate(tour?.TourURL);
              }}
            >
              {tour?.TourName}
            </h6>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecViewedPackages;
