import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getWeekendGatewayData = createAsyncThunk(
  "CustomizedHoliday/WeekendGatewayPageData",
  async (payload) => {
    const response = await axiosApiInstance.post(
      `${API_APP_URL}/CustomizedHoliday/WeekendGatewayPageData`,
      payload
    );
    const WeekendGatewayData = response.data.Data;
    return WeekendGatewayData;
  }
);

const initialState = {
  loading: false,
  error: "",
  WeekendGatewayData: [],
};

const weekendGatewaySlice = createSlice({
  name: "weekendGatewaySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWeekendGatewayData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWeekendGatewayData.fulfilled, (state, action) => {
      state.loading = false;
      state.WeekendGatewayData = action.payload;
      state.error = "";
    });
    builder.addCase(getWeekendGatewayData.rejected, (state, action) => {
      state.loading = false;
      state.WeekendGatewayData = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default weekendGatewaySlice.reducer;
