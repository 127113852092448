import React from "react";
import { useSelector } from "react-redux";
import { Element } from "react-scroll";

const CancellationPolicy = () => {
  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);

  return (
    <Element
      name="Cancellation-Policy-tab"
      style={{
        textAlign: "justify",
        padding: 15,
        boxShadow: "0 0 10px lightgray",
        borderRadius: 20,
        marginBottom: 30,
      }}
      dangerouslySetInnerHTML={{
        __html: TourDetails?.CancellationPolicy || "",
      }}
    />
    // <div dangerouslySetInnerHTML={{ __html: TourDetails?.CancellationPolicy || "" }} />
  );
};

export default CancellationPolicy;
