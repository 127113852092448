import React, { useEffect, useState } from "react";
import LOGO from "../../assets/images/logo.png";
import SEARCH from "../../assets/images/search.png";
import SEARCH_RED from "../../assets/images/search-red.png";
import LEFT_ARROW from "../../assets/images/arrow-left.png";
import { useLocation, useNavigate } from "react-router-dom";
import { HEADER_DATA } from "../../common/headerData";
import {
  COUPLE_TOUR_PACKAGE_PATH_KEY,
  INDIAN_PACKAGE_PATH_KEY,
  INTERNATIONAL_PACKAGE_PATH_KEY,
  WEEKEND_GATEWAYS_PACKAGE_PATH_KEY,
} from "../../common/costants";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname.split("/")[1];
  const IsTourDetail =
    currentPage === "package" ||
    (currentPage === "weekend-gateways" && location.pathname.split("/")[3]);
  const headerclassName = `${IsTourDetail ? "tour-single" : ""}`;
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState("India");
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const shouldFixHeader = scrollTop >= 300;
      setIsHeaderFixed(shouldFixHeader);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSearchIconClick = () => {
    setIsSearchActive(!isSearchActive);
  };

  const handleNavigate = (item) => {
    let parentHeader;
    let secondaryHeader;
    let child;

    HEADER_DATA.some((menu) => {
      if (menu.Title === item.Title) {
        parentHeader = menu.Title;
        return true;
      }

      return menu.Items.some((subMenu) => {
        if (subMenu.Title === item.Title) {
          parentHeader = menu.Title;
          secondaryHeader = subMenu.Title;
          return true;
        }

        return subMenu.Items.some((data) => {
          if (data.Title === item.Title) {
            parentHeader = menu.Title;
            secondaryHeader = subMenu.Title;
            child = data.Title;
            return true;
          }
          return false;
        });
      });
    });

    if (item?.Url) {
      if (parentHeader) {
        if (parentHeader === "Home") {
          navigate(`/`);
        } else if (parentHeader === "India") {
          navigate(`${INDIAN_PACKAGE_PATH_KEY}/${item.Url}-tour-packages/`);
        } else if (parentHeader === "International") {
          window.open(`${item.Url}`, "_blank");
          // navigate(
          //   `${INTERNATIONAL_PACKAGE_PATH_KEY}/${item.Url}-tour-packages/`
          // );
        } else if (parentHeader === "Sasta Air Ticket") {
          window.open(`${item.Url}`, "_blank");
        } else if (parentHeader === "Weekend Gateways") {
          navigate(
            `${WEEKEND_GATEWAYS_PACKAGE_PATH_KEY}/${item.Url}-tour-packages/`
          );
        } else if (parentHeader === "Couple Tours") {
          navigate(`${COUPLE_TOUR_PACKAGE_PATH_KEY}/${item.Url}/`);
        } else if (parentHeader === "New Packages") {
          window.open(`${item.Url}`, "_blank");
        } else {
          navigate(`${item.Url}/`);
        }
        setSidebarOpen(!sidebarOpen);
      } else {
        navigate(`${item.Url}/`);
        setSidebarOpen(!sidebarOpen);
      }
    }
  };

  const handleMouseOver = (item) => {
    if (item?.Title !== "" && item?.Items.length > 0 && !isSubmenuOpen) {
      setSubmenuOpen(item?.Title);
    }
  };

  const handleMenuClick = (item) => {
    if (item?.Items && item?.Items.length > 0) {
      setSubmenuOpen(item?.Title);
      setIsSubmenuOpen(true);
    } else if (item.Url) {
      handleNavigate(item);
    }
  };

  const handleMenuToggleClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavButtonclick = (text) => {
    if (text === "Domestic") {
      navigate(`/domestic-destinations/`);
    } else if (text === "International") {
      window.open(`https://international.ajaymodi.com/`, "_blank");
    } else if (text === "Sasta Air Ticket") {
      window.open(`https://ajaymodi.co/`, "_blank");
    }
  };

  const handleMenuCloseClick = () => {
    setSidebarOpen(false);
  };

  return (
    <>
      <header className={headerclassName}>
        <div
          className={`container container-1480 sticky ${
            isHeaderFixed ? "fixed-header" : ""
          }`}
        >
          <div className={`row ${isHeaderFixed ? "visible-title" : ""}`}>
            <div className="col-sm-4 logo-sec">
              <div className="logo-site">
                <a href="/">
                  <img src={LOGO} alt="Ajay Modi Travels" />
                </a>
              </div>
            </div>
            <div className="col-sm-8 menu-col">
              {/* <div className="search-icon">
                <a>
                  <img
                    src={IsTourDetail ? SEARCH_RED : SEARCH}
                    alt="Search"
                    onClick={handleSearchIconClick}
                  />
                </a>
              </div> */}
              <div
                className={`toggle-form ${isSearchActive ? "menu-active" : ""}`}
              >
                <input type="text" placeholder="search" />
              </div>
              <button
                className="nav-btn"
                onClick={() => {
                  handleNavButtonclick("Sasta Air Ticket");
                }}
              >
                सस्ता Air Ticket
              </button>
              <button
                className="nav-btn"
                onClick={() => {
                  handleNavButtonclick("Domestic");
                }}
              >
                Domestic
              </button>
              <button
                className="nav-btn"
                onClick={() => {
                  handleNavButtonclick("International");
                }}
              >
                International
              </button>
              <button className="menu-toggle" onClick={handleMenuToggleClick}>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div
                className={`navigation-main ${
                  sidebarOpen ? "menu-active" : ""
                }`}
              >
                <div className="menu-main">
                  <ul className="nav-menu">
                    {HEADER_DATA.map((menuItem) => (
                      <li key={menuItem.Title} data-id={menuItem.Title}>
                        <a
                          onMouseOver={() => handleMouseOver(menuItem)}
                          onClick={() => handleMenuClick(menuItem)}
                          className="cursor-pointer"
                        >
                          {menuItem.Title}
                        </a>
                      </li>
                    ))}
                  </ul>

                  {submenuOpen && (
                    <div className="submenu-nav">
                      <div
                        className={`submenu-items ${
                          isSubmenuOpen ? "mobile-slide" : ""
                        }`}
                      >
                        <div className="close-submenu">
                          <img
                            src={LEFT_ARROW}
                            onClick={() => setIsSubmenuOpen(false)}
                            alt="Close submenu"
                          />
                        </div>
                        <h5>{submenuOpen}</h5>
                        <ul>
                          {HEADER_DATA.find(
                            (menuItem) => menuItem.Title === submenuOpen
                          )?.Items.map((submenuItem) => (
                            <li key={submenuItem.Title}>
                              <a
                                onClick={() => handleNavigate(submenuItem)}
                                className="cursor-pointer"
                              >
                                {submenuItem.Title}
                              </a>
                              {/* {submenuItem.Items &&
                                submenuItem.Items.length > 0 && (
                                  <ul class="kashmir-submenu">
                                    {submenuItem.Items?.map((sub) => (
                                      <li key={sub.Title}>
                                        <a
                                          onClick={() => handleNavigate(sub)}
                                          className="cursor-pointer"
                                        >
                                          {sub.Title}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                )} */}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="close-btn-menu"
                  onClick={handleMenuCloseClick}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </header>
    </>
  );
};

export default Header;
