import React from "react";
import TESTIMONIAL_BG from "../../assets/images/testimonial-bg.jpg";
import QUOTE_ICON from "../../assets/images/white-quoter.png";
import { useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import { LONG_DESCRIPTION_LENGTH } from "../../common/costants";
import { lengthTruncator } from "../../common/helpers";
import Slider from "react-slick";

const TestimonialSection = () => {
  const HomeData = useSelector((state) => state.home.HomeData);

  const settings = {
    className: "testimonial-slick",
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  return (
    <div className="testimonial-section full-section relative">
      <div className="bg-sections">
        <img src={TESTIMONIAL_BG} />
      </div>
      <div className="container relative">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="testimon-col-left">
              <h2>Testimonials</h2>
              <p>
                At Ajay Modi Travels, the satisfaction and joy of our travelers
                are at the core of everything we do. Here's what some of our
                valued clients have shared about their experiences with us:
              </p>
              {/* <a href="#" className="btn btn-fill round">
                Explore
              </a> */}
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            {/* <div className="row"> */}
            <Slider {...settings}>
              {/* <OwlCarousel
              className="owl-carousel testimonial-owl owl-theme"
              loop
              margin={20}
              dots={false}
              nav
              autoplay={true}
              autoplayTimeout={4000}
              autoplayHoverPause={true}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                1000: {
                  items: 2,
                },
                1200: {
                  items: 3,
                },
              }}
            > */}
              {HomeData?.TestimonialList?.map((test) => (
                <div className="item">
                  <div className="testimonial-box-slid row">
                    {/* <div className="testimonial-pic col-md-3 col-sm-12">
                      <img src={test?.TestimonialImage} />
                    </div> */}

                    <div className="testimonial-data col-md-12 col-sm-12">
                      <div className="quote-ico">
                        <img src={QUOTE_ICON} />
                      </div>
                      <h4>{test?.Name}</h4>
                      {/* <small>{test?.ShortDescription}</small> */}
                      <p>
                        {lengthTruncator(
                          test?.LongDescription,
                          LONG_DESCRIPTION_LENGTH
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            {/* </div> */}
            {/* </OwlCarousel> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
