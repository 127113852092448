import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getContactUsDetail = createAsyncThunk(
  "HomePage/ContactUsDetail",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_APP_URL}/HomePage/GetContactCMSPageDetails?pageURL=contactus`
    );
    const ContactUsData = response.data?.Data || [];
    return ContactUsData;
  }
);

const initialState = {
  loading: false,
  error: "",
  ContactUsData: {
    ContactUsData: "",
  },
};

const contactSlice = createSlice({
  name: "contactSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContactUsDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getContactUsDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.ContactUsData = action.payload;
      state.error = "";
    });
    builder.addCase(getContactUsDetail.rejected, (state, action) => {
      state.loading = false;
      state.ContactUsData = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default contactSlice.reducer;
