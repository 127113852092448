import { v4 as uuidv4 } from "uuid";

export const lengthTruncator = (data, length) => {
  return `${data?.slice(0, length)}${data?.length > length ? "..." : ""}`;
};

export const getOrignalURL = (url) => {
  if (url) {
    const parts = url.split("-tour-packages");
    return parts[0];
  }
};

export const getSessionId = () => {
  const storedSessionId = localStorage.getItem("sessionId");
  return storedSessionId || uuidv4();
};

export const setSessionId = (sessionId) => {
  localStorage.setItem("sessionId", sessionId);
};
