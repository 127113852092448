import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL, API_CRM_URL } from "../../app/config";

export const getHomeData = createAsyncThunk("Home/AllData", async () => {
  const response = await axiosApiInstance.get(
    `${API_APP_URL}/HomePage/HomePageAllData`
  );
  const HomeData = response.data?.Data || {};

  return HomeData;
});

export const getAllDestinations = createAsyncThunk(
  "Destination/FindAllActive",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_CRM_URL}/destination/findallactive`
    );
    const DestinationList = response.data?.Data || [];

    return DestinationList;
  }
);

export const getCitiesByDestId = createAsyncThunk(
  "Cities/FindByDestId",
  async (payload) => {
    const response = await axiosApiInstance.post(
      `${API_APP_URL}/HomePage/HomePageCitiListByURL`,
      payload
    );
    const CitiesList = response.data?.Data?.homePageCities || [];
    const SectorName = response.data?.Data?.SectorName || "";
    const ShortDescription = response.data?.Data?.ShortDescription || "";

    return { CitiesList, SectorName, ShortDescription };
  }
);

export const getDestinationsByRegId = createAsyncThunk(
  "Cities/FindByRegId",
  async (payload) => {
    const response = await axiosApiInstance.post(
      `${API_APP_URL}/HomePage/HomePageDestinationListByRegionID`,
      payload
    );
    const DestinationList = response.data?.Data?.DestinationList || [];
    // const DestinationList = response.data?.Data || [];
    const ZoneName = response.data?.Data?.ZoneName || "";
    const ShortDescription = response.data?.Data?.ShortDescription || "";

    return { DestinationList, ZoneName, ShortDescription };
  }
);

export const sendInquiry = createAsyncThunk(
  "Home/sendInquiry",
  async (payload) => {
    const response = await axiosApiInstance.post(
      `${API_APP_URL}/Inquiry/InquiryInsert`,
      payload
    );
    // return response.data?.Data
  }
);

export const sendInquiryForPune = createAsyncThunk(
  "Home/PuneInquiryInsert",
  async (payload) => {
    const response = await axiosApiInstance.post(
      `${API_APP_URL}/Inquiry/PuneInquiryInsert`,
      payload
    );
    // return response.data?.Data
  }
);

export const sendInquiryToNeoDoveSoftware = createAsyncThunk(
  "Home/sendInquiryToNeoDoveSoftware",
  async (payload) => {
    const response = await axiosApiInstance.post(
      `https://api.neodove.com/integration/custom/a14421ca-59bc-43dc-8241-458cac808378/leads`,
      payload
    );
    // return response.data?.Data
  }
);

const initialState = {
  loading: false,
  inquiryLoading: false,
  neodoveInquiryLoading: false,
  destLoading: false,
  citiesLoading: false,
  CitiesData: {},
  ZoneData: {},
  modalOpen: false,
  error: "",
  HomeData: {},
  DestinationList: [],
  InquiryData: {},
};

const homeSlice = createSlice({
  name: "homeSlice",
  initialState,
  reducers: {
    openDialog: (state) => {
      state.modalOpen = true;
    },
    closeDialog: (state) => {
      state.modalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHomeData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHomeData.fulfilled, (state, action) => {
      state.loading = false;
      state.HomeData = action.payload;
      state.error = "";
    });
    builder.addCase(getHomeData.rejected, (state, action) => {
      state.loading = false;
      state.HomeData = [];
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllDestinations.pending, (state) => {
      state.destLoading = true;
    });
    builder.addCase(getAllDestinations.fulfilled, (state, action) => {
      state.destLoading = false;
      state.DestinationList = action.payload;
      state.error = "";
    });
    builder.addCase(getAllDestinations.rejected, (state, action) => {
      state.destLoading = false;
      state.DestinationList = [];
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getCitiesByDestId.pending, (state) => {
      state.citiesLoading = true;
    });
    builder.addCase(getCitiesByDestId.fulfilled, (state, action) => {
      state.citiesLoading = false;
      state.CitiesData = action.payload;
      state.error = "";
    });
    builder.addCase(getCitiesByDestId.rejected, (state, action) => {
      state.citiesLoading = false;
      state.CitiesData = {};
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getDestinationsByRegId.pending, (state) => {
      state.citiesLoading = true;
    });
    builder.addCase(getDestinationsByRegId.fulfilled, (state, action) => {
      state.citiesLoading = false;
      state.ZoneData = action.payload;
      state.error = "";
    });
    builder.addCase(getDestinationsByRegId.rejected, (state, action) => {
      state.citiesLoading = false;
      state.ZoneData = {};
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(sendInquiry.pending, (state) => {
      state.inquiryLoading = true;
    });
    builder.addCase(sendInquiry.fulfilled, (state, action) => {
      state.inquiryLoading = false;
      state.InquiryData = action.payload;
      state.error = "";
    });
    builder.addCase(sendInquiry.rejected, (state, action) => {
      state.inquiryLoading = false;
      state.InquiryData = [];
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(sendInquiryToNeoDoveSoftware.pending, (state) => {
      state.neodoveInquiryLoading = true;
    });
    builder.addCase(sendInquiryToNeoDoveSoftware.fulfilled, (state, action) => {
      state.neodoveInquiryLoading = false;
      // state.InquiryData = action.payload;
      state.error = "";
    });
    builder.addCase(sendInquiryToNeoDoveSoftware.rejected, (state, action) => {
      state.neodoveInquiryLoading = false;
      // state.InquiryData = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default homeSlice.reducer;
export const { openDialog, closeDialog } = homeSlice.actions;
