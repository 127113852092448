import React from "react";
import { useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import Slider from "react-slick";

const HomeBannerSlider = () => {
  const HomeData = useSelector((state) => state.home.HomeData);

  const settings = {
    className: "tabbing-slick",
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <div
      className="home-slider"
      style={{ backgroundImage: `url(${HomeData?.staticBanner?.BannerImage})` }}
    >
      {/* <OwlCarousel
        className="owl-carousel banner-owl owl-theme"
        loop
        margin={0}
        dots={false}
        nav={false}
        autoplay={true}
        autoplayTimeout={4000}
        autoplayHoverPause={true}
        responsive={{
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1199: {
            items: 1,
          },
          1400: {
            items: 1,
          },
        }}
      > */}
      <Slider {...settings}>
        {HomeData?.SliderBanner?.map((banner) => (
          <div className="item">
            <div className="container">
              <div className="banner-text-boxes">
                <div className="banner-box">
                  <div className="container">
                    <div className="banner-leftside">
                      {/* need to change it like dynamicaly  */}
                      <h2>Welcome to</h2>
                      <h3>#Amazing Ajay Modi</h3>
                      <p>INDIA NAHI DEKHA TOH KUCHH NAHI DEKHA</p>
                    </div>
                  </div>
                </div>
                <div className="banner-pic">
                  <img src={banner?.BannerImage} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      {/* </OwlCarousel> */}
    </div>
  );
};

export default HomeBannerSlider;
